<script setup>
import { useRouter } from 'vue-router'
import KtpJSON from "../../assets/briscan.json"
import Navigation from "../Navigation.vue";


const router = useRouter()

const goToUpgradeCard = () => {
    localStorage.setItem('mode','GK')
    router.push({ name: "GKLetakanKtp" });
}

const goToPrintCard = () => {
    localStorage.setItem('mode','CK')
    router.push({ name: "CKLetakanKtp" });
}


</script>

<template>
  <Navigation back="StartingPage" cancel="StartingPage" />
  <div class="ktp-content">
    <div class="textktp">
      <div>Pilih Jenis Pembaharuan Kartu</div>
    </div>
    <div>
      <button @click="goToUpgradeCard()">Ganti Jenis Kartu</button>
    </div>
    <div>
      <button @click="goToPrintCard()">Cetak Kartu (<span style="color:orange"> Kartu Hilang </span>)</button>
    </div>
  </div>
</template>

<style scoped>
.ktp-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 0px;
  flex-grow: 1;
  margin-bottom: 84px;
  justify-content: space-evenly;

  font-family: "Lato", sans-serif;
  font-weight: 400;
  font-size: 25px;
  line-height: 30px;
  text-align: center;
  color: var(--primary-blue);
}

.ktp-content button {
  /* padding: 21px 94px;
  cursor: pointer;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: var(--primary-white);
  background: var(--primary-blue);
  border: none;
  filter: drop-shadow(0px 3px 16px rgba(124, 124, 124, 0.133));
  border-radius: 20px; */
  height: 83px;
  width: 420px;
  background: var(--primary-blue);
  font-weight: 200;
  font-size: 28px;
  line-height: 34px;
  /* color: var(--primary-white); */
  color: var(--primary-white);
  filter: drop-shadow(0px 3px 16px rgba(124, 124, 124, 0.133));
  border: none;
  border-radius: 7px;
  z-index: 10;
}

.textktp {
  margin-top: -150px;
  margin-bottom: 20px;
}

.command {
  display: flex;
  justify-content: space-between;
  padding: 0 40px;
  font-weight: 600;
}

.command .navigation {
  width: 100px;
  height: 50px;
  font-size: 14px;
  padding: 0;
  margin-top: 10px;
}
</style>
