<script setup>
import { useRouter } from "vue-router";
const router = useRouter()

const goToPrintCard = () => {
  router.push({ name: "GKCetakKartu" })
}
</script>
<template>
  <div class="card-details-content">
    <div>
      <div class="card-details-title">
        Pilih Jenis Kartu yang anda inginkan
      </div>
      <div class="container">
        <!-- they should all have the same name attr but different ids -->
        <label for="card_one">
          <div class="card" style="background-color: whitesmoke;	box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);border: 2px solid var(--primary-bri-secondary);">
            <div><img src="../../assets/card/oldsilver.png" class="card-image" /></div>
            <div class="card-content">
              <div class="card-title">
                <div class="card-active">
                  <div>
                    <!-- <input type="radio" name="card" id="card_one" disabled /> Silver Card -->
                    <div style="min-width: 30px;min-height: 30px;background-color: var(--primary-bri-secondary);border-radius: 50%;margin-left: 10px;"></div>
                  </div>
                  <div>Kartu Anda saat ini</div>
                </div>
              </div>
              <div class="card-description">
                Nominal pengambilan tuna di atm sebesar
                <b>Rp. 1.200.000,-/hari</b>. Untuk Transfer ke Bank lain
                sebesar <b>Rp. 10.000.000,-/transaksi </b>.
              </div>
            </div>
          </div>
        </label>
        <label for="card_two">
          <div class="card" @click="goToPrintCard">
            <div><img src="../../assets/card/oldgold.png" class="card-image" /></div>
            <div class="card-content">
              <div class="card-title">
                <input type="radio" name="card" id="card_two" /> Gold Card
              </div>
              <div class="card-description">
                Nominal pengambilan tuna di atm sebesar
                <b>Rp. 5.000.000,-/hari</b>. Untuk Transfer ke Bank lain
                sebesar <b>Rp. 50.000.000,-/transaksi</b>.
              </div>
            </div>
          </div>
        </label>
        <label for="card_three">
          <div class="card" @click="goToPrintCard">
            <div><img src="../../assets/card/oldblack.png" class="card-image" /></div>
            <div class="card-content">
              <div class="card-title">
                <input type="radio" name="card" id="card_three" /> Platinum
                Card
              </div>
              <div class="card-description">
                Nominal pengambilan tuna di atm sebesar
                <b>Rp. 100.000.000,-/hari</b>. Untuk Transfer ke Bank lain
                sebesar <b>Rp. 1.000.000.000,-/transaksi</b>.
              </div>
            </div>
          </div>
        </label>
      </div>
    </div>
  </div>
</template>


<style scoped>
.card-details-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 35px;
  flex-grow: 1;
  margin-bottom: 84px;
  justify-content: space-between;

  font-family: "Lato", sans-serif;
  font-weight: 400;
  font-size: 25px;
  line-height: 30px;
  text-align: center;
  color: var(--primary-blue);
}

.card-details-title {
  margin-bottom: 10px;
  font-weight: 400;
  font-size: 25px;
  line-height: 30px;
  text-align: left;
  color: var(--primary-blue);
}

.container {
  display: flex;
  flex-direction: column;
}
.img {
  width: 200px;
  height: 200px;
  border: 0.3rem solid #fff;
  border-radius: 50%;
  box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.4);
  overflow: hidden;
}
.card-image {
  width: 116px;
  height: 73px;
  object-fit: cover;
  user-select: none;
  margin-right: 16px;
}
.card {
  background-color: var(--primary-white);
  padding: 25px;
  max-width: 688px;
  display: flex;
  align-items: center;
  margin-bottom: 21px;
  border-radius: 10px;
  border: 1px solid #b7b7b7;
  box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  /* margin: 0 1rem;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 0.5rem;
  position: relative;
  cursor: pointer;
  border: 0.5rem solid transparent;
  transition: 200ms ease-in-out transform; */
}

.card-content {
  max-width: 479px;
}

.card-title {
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #4155aa;
  text-align: left;
  margin-bottom: 8px;
}

.card-active {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.card-description {
  text-align: left;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: #343434;
}
.check_btn {
  position: absolute;
  top: 8%;
  right: 8%;
  background-color: rgba(92, 92, 92, 0.4);
  width: 2rem;
  height: 2rem;
  display: grid;
  place-items: center;
  border-radius: 0.5rem;
}
.fa-check {
  color: #fff;
}
.title {
  margin: 1.5rem 0;
}
.title,
ul li {
  color: #1f1208;
  text-shadow: 0 0 0.1rem rgba(0, 0, 0, 0.4);
}

.card:hover {
  transform: scale(1.02);
}
.card:active {
  transform: scale(0.97);
}

input[id="card_one"]:checked ~ label[for="card_one"] .card,
input[id="card_two"]:checked ~ label[for="card_two"] .card,
input[id="card_three"]:checked ~ label[for="card_three"] .card {
  border: 3px solid var(--primary-blue);
}
/* you can see based on which card is checked the border color will be changed */
/* ~ selector selects the sibling elements, in this case the label */

input[id="card_one"]:checked ~ label[for="card_one"] .card .check_btn,
input[id="card_two"]:checked ~ label[for="card_two"] .card .check_btn,
input[id="card_three"]:checked ~ label[for="card_three"] .card .check_btn {
  background-color: rgb(21, 238, 21);
}


input[id="card_one"]:checked ~ label[for="card_one"] .card .img,
input[id="card_two"]:checked ~ label[for="card_two"] .card .img,
input[id="card_three"]:checked ~ label[for="card_three"] .card .img {
  border-color: rgb(21, 238, 21);
  box-shadow: 0 0 0.5rem rgba(21, 238, 21, 0.6);
}

/* its all done and the last thing is we need to remove the input radio btns */
input {
  /* display: none; */
  height: 30px;
}

</style>