<script setup>
import { onMounted } from "vue";
import { useRouter } from "vue-router";
import Ektp from "./mixins/ektp.js";
import Grg from "./mixins/grg.js";
import PrintJSON from "../assets/print.json"
import OpeningAccountJSON from "../assets/pembukaanRekening.json"
import ChangeCardJSON from "../assets/gantiKartu.json"
import store from "../store"


const router = useRouter();
mixins: [Ektp, Grg]

onMounted(() => {
  Grg.methods.OpenSDK()
  store.dispatch('sdkpinRequest', true)

});


function goToOpeningAccount() {
  localStorage.setItem('mode','BR')
  router.push({ name: "LetakanKtp" });
}

function goToChangeCard() {
  // localStorage.setItem('mode','GK')
  // router.push({ name: "GKLetakanKtp" });
  router.push({ name: "GKSelectChangeCard" });
}

// function goToPrintCard() {
//   localStorage.setItem('mode','CK')
//   router.push({ name: "CKLetakanKtp" });
// }

function openPort() {
  Ektp.methods.getPort()
}

</script>

<template>
<video autoplay muted loop id="myVideo">
  <source src="../assets/vid.mp4" type="video/mp4" style="width:100%">
</video>
 <div class="wrapMenuBtn">
   <div class="landing-footer" >
     <div class="button-wrapper">
       
       <button type="button" class="button btnMenuPrimary" @click="goToOpeningAccount()">
         <span class="button__icon" style="margin-left: 10px;">
           <LottieAnimation :animationData="OpeningAccountJSON" :height="100" :width="100" />
         </span>
         <div class="wrapDesc">
           <span class="button__text" style="font-size:22px">Pembukaan Rekening</span>
            <p class="descBtn" style="color:var(--primary-white)">Pembuatan rekening baru</p>
         </div>

        </button>
 
       <button type="button" class="button btnMenu" @click="goToChangeCard()">
         <span class="button__icon" style="margin-left: 20px;">
           <LottieAnimation :animationData="ChangeCardJSON" :height="100" :width="100" />
         </span>
         <div class="wrapDesc">
          <span class="button__text" style="font-size: 22px;">Ganti Kartu</span>
          <p class="descBtn">Penggantian Jenis Kartu</p>
         </div>
       </button>
 
       <!-- <button type="button" class="button btnMenu" @click="goToPrintCard()">
         <span class="button__icon">
           <LottieAnimation :animationData="PrintJSON" :height="100" :width="100" />
         </span>
         <div class="wrapDesc">
         <span class="button__text" style="margin-left: 15px;">Cetak Kartu</span>
          <p class="descBtn">Penggantian Kartu Hilang</p>
         </div>
       </button> -->
     </div>
    </div>
  </div>
  <button class="setting" @click="openPort"></button>
</template>


<style scoped>
 .setting{
    color: black;
    background-color: transparent;
    position: absolute;
    font-size: 10px;
    bottom: 0;
    left: 0;
    max-width: 10px;
  }
 .setting2{
    color: black;
    position: absolute;
    bottom: 0;
    right: 0;
    background-color: none;
    background-color: black!important;
    width: 10px!important;
    height: 10px!important;
  }

  .button {
    display: inline-flex;
    padding: 0;
    border: none;
    outline: none;
    overflow: hidden;
    box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
    min-height: 150px;
  }

  .button__text,.button__icon {
    display: inline-flex;
    align-items: center;
    height: 100%;
  }

  .button__icon {
    font-size: 1.5em;
  }

  .btnMenu {
    background-color: whitesmoke;
    color: var(--primary-blue);
    border-radius: 13px;
  }
  .btnMenuPrimary {
    background-color: var(--primary-blue);
    color: var(--primary-white);
    border-radius: 13px;
  }

  .wrapMenuBtn {
    position: relative;
    top: 300px;
  }

  .descBtn {
    font-weight: 600;
    font-size: 14px;
    margin-top: 0;
    color: var(--primary-blue);
  }

  .wrapDesc {
    margin-top: 60px;
    text-align: left;
  }

  #myVideo {
    position: fixed;
    right: 0;
    bottom: 0;
    top: 142px;
    min-width: 100%;
    min-height: 100%;
  }

</style>


