<script setup>
import { useRouter } from "vue-router";
import Navigation from "../Navigation.vue";
const router = useRouter();


const goToCardOut = () => {
  router.push({ name: "CetakKartu" });
};
</script>

<template>
  <Navigation back="PinInput" cancel="StartingPage" />
  <div class="confirm-card-content">
    <div class="confirm-card-detail">
      <div class="confirm-card-image">
        <img :src="require(`@/assets/card/${$store.state.cardRequest.path}`)" class="card-img" />
      </div>
      <div class="confirm-card-description">
        <div class="confirm-card-description-item">
          <div class="confirm-card-description-item-key">No. Rek</div>
          <div class="confirm-card-description-item-value" style="color:var(--primary-bri-secondary);fontWeight:700;">
            : 1234 - 456 - 7890
          </div>
        </div>
        <div class="confirm-card-description-item">
          <div class="confirm-card-description-item-key">Jenis Kartu</div>
          <div class="confirm-card-description-item-value">: {{$store.state.cardRequest.name}}</div>
        </div>
        <div class="confirm-card-description-item">
          <div class="confirm-card-description-item-key">Tipe Kartu</div>
          <div class="confirm-card-description-item-value">
            : Tanpa Nama (instan)
          </div>
        </div>
        <div class="confirm-card-description-item">
          <div class="confirm-card-description-item-key">Masa Berlaku</div>
          <div class="confirm-card-description-item-value">: November 2027</div>
        </div>
        <div class="confirm-card-description-item">
          <div class="confirm-card-description-item-key">Setoran Awal</div>
          <div class="confirm-card-description-item-value">: Rp.500.000,-</div>
        </div>
      </div>
    </div>
    <div class="request-loader">
      <button @click="goToCardOut()">Konfirmasi</button>
    </div>
  </div>
</template>

<style scoped>
.confirm-card-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 75px;
  flex-grow: 1;
  margin-bottom: 84px;
  justify-content: space-around;

  font-family: "Lato", sans-serif;
  font-weight: 400;
  font-size: 25px;
  line-height: 30px;
  text-align: center;
  color: var(--primary-blue);
}

.confirm-card-detail {
  display: flex;
  align-items: start;
  width: 80%;
}

.confirm-card-image {
  width: 50%;
}

.confirm-card-description {
  width: 50%;
  text-align: left;
  margin-left: 20px;
}

.confirm-card-description-item {
  width: 100%;
  display: flex;
  margin-bottom: 21px;
}

.confirm-card-description-item-key {
  width: 30%;
  font-weight: 400;
  font-size: 25px;
  line-height: 38px;
  color: var(--primary-blue);
}

.confirm-card-description-item-value {
  width: 70%;
  font-weight: 400;
  font-size: 28px;
  line-height: 32px;
  color: #3b3b3b;
}


.confirm-card-content button {
  height: 83px;
  width: 269px;
  font-weight: 200;
  font-size: 28px;
  line-height: 34px;
  background: var(--primary-blue);
  color: var(--primary-white);
  filter: drop-shadow(0px 3px 16px rgba(124, 124, 124, 0.133));
  border: none;
  border-radius: 7px;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
}

.card-img {
  width: 350px;
  height: 207px;
  margin-bottom: 22px;
}
</style>
