
<script>
// import moment from 'moment';
import { format } from "date-fns"

const getCurrentTime = () => format(new Date(),"HH:mm:ss");
const getCurrentDay = () => format(new Date(),"EEEE d LLL uuuu");

export default {
  name: "App",
  data() {
    return {
      currentTime: getCurrentTime(),
      day: getCurrentDay()
    }
  },
  methods: {
    date() {
      return moment().format('dddd  MMMM Do YYYY'); 
    },
    clock() {
      return moment().format('h:mm'); 
    },
    updateCurrentTime() {
      this.currentTime = getCurrentTime();
    },
    updateCurrentDay() {
      this.day = getCurrentDay();
    }
  },
  created() {
    setInterval(() => this.updateCurrentTime(), 1000);
  }
};

</script>

<template>
  <div class="transparant">
    <div class="landing-container">
      <div class="landing-header">
        <div class="landing-time-wrapper">
          <div class="landing-clock">{{currentTime}}</div>
          <div class="landing-date">{{day}}</div>
        </div>
        <div class="wrapLogo">
          <img src="./assets/logo_sentuhNew.png" alt="bri">
        </div>
      </div>
      <router-view></router-view>
    </div>
  </div>
</template>


<style>
html {
  touch-action: none;
}
:root {
  --primary-black: #343434;
  --primary-blue: #1f3283;
  --primary-white: #ffffff;
  /* --primary-blue: #00A39D; */
  --primary-bri-secondary: #f59823;
  /* --primary-bri-secondary: #f6ad3c; */
}

body {
  margin: 0;
  padding: 0;
  font-family: "Inter", sans-serif;
}

/* p {
  font-family: poppins;
  font-weight: 500;
} */
.landing-container {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  background-image: url("assets/bg-image.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.landing-header {
  height: 120px;
  background: #ffffff;
  filter: drop-shadow(0px 3px 15px rgba(0, 0, 0, 0.161));
  padding: 12px 47px 12px 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  /* From https://css.glass */
/* background: rgba(255, 255, 255, 0.2);
box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
backdrop-filter: blur(3px);
-webkit-backdrop-filter: blur(5px);
border: 1px solid rgba(255, 255, 255, 0.3); */
}

.landing-time-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.landing-clock {
  font-weight: 700;
  font-size: 50px;
  line-height: 61px;
  color: var(--primary-blue);
}

.landing-date {
  font-weight: 400;
  font-size: 19px;
  line-height: 23px;
  color: var(--primary-black);
}

.landing-content {
  margin-top: 62px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.landing-footer {
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: end;
  padding-bottom: 104px;
}



.button-wrapper {
  width: 915px;
  display: flex;
}

.landing-footer button {
  flex: 1;
  height: 83px;
  margin: 12px;
  cursor: pointer;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: var(--primary-blue);
  background: var(--primary-white);
  border: none;
  filter: drop-shadow(0px 3px 16px rgba(124, 124, 124, 0.133));
  border-radius: 20px;
}

 /* Modal */
 .modal-window {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.6);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s;
}
.modal-window:target {
  visibility: visible;
  opacity: 1;
  pointer-events: auto;
}
.modal-window > div {
  width: 600px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 2em;
  background: white;
}
.modal-window header {
  font-weight: bold;
}
/* .modal-window h1 {
  font-size: 150%;
  margin: 0 0 15px;
} */
.modal-close {
  color: #aaa;
  line-height: 50px;
  font-size: 80%;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  width: 70px;
  text-decoration: none;
}
.modal-close:hover {
  color: black;
}

.modal {
  display: block; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
  padding-top: 15%;
}

/* Modal Content */
.modal-content {
  background-color: #ffffff;
  margin: auto;
  padding-top: 60px;
  /* border: 1px solid #888; */
  width: 555px;
  height: 40%;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  position: relative;
}

.header-circle {
  position: absolute;
  width: 100px;
  height: 100px; /* as the half of the width */
  background-color: #ffffff;
  z-index: 1;
  top: -81px;
  width: 160px;
  height: 84px;
  border-top-left-radius: 120px; /* 100px of height + 10px of border */
  border-top-right-radius: 120px; /* 100px of height + 10px of border */
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 34px;
}

.dot {
  height: 128px;
  width: 128px;
  background-color: var(--primary-white);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  filter: drop-shadow(0px 2px 18px rgba(0, 0, 0, 0.29));
}

.failed-scan-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.content-text {
  margin-top: 75px;
  font-weight: 700;
  font-size: 25px;
  line-height: 30px;
  color: #000000;
  display: flex;
  align-items: center;
  text-align: center;
  flex-direction: column;
}

.content-text-margin {
  margin-top: 25px;
  font-weight: 500;
  font-size: 20px;
}

.button-failed-scan {
  background: #4155aa;
  color: var(--primary-white);
  font-weight: 700;
  font-size: 28px;
  line-height: 34px;
  padding: 21px 112px;
  border: none;
  filter: drop-shadow(0px 3px 16px rgba(124, 124, 124, 0.133));
  border-radius: 10px;
}

.transparant {
  background: rgba(0, 0, 0, 0.05);
  width: 100%;
}

.wrapLogo {
  width: 239px;
  height: 70px;
  overflow: hidden;
}

.wrapLogo img {
  width: 100%;
}



</style>
