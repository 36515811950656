<script  setup>
import { onMounted } from "vue";
import { useRouter } from "vue-router";
import Ektp from "../mixins/ektp";
import Navigation from "../Navigation.vue";


const router = useRouter();
mixins: [Ektp]

let tmp = ""
let message = "Sentuh disini untuk menampilkan keyboard"

  const Keyboard = {
  elements: {
    main: null,
    keysContainer: null,
    keys: [],
  },

  eventHandlers: {
    oninput: null,
    onclose: null,
  },

  properties: {
    value: "",
    capsLock: false,
  },

  init() {
    // Create main elements
    this.elements.main = document.createElement("div");
    this.elements.keysContainer = document.createElement("div");

    // Setup main elements
    this.elements.main.classList.add("keyboard", "keyboard--hidden");
    this.elements.keysContainer.classList.add("keyboard__keys");
    this.elements.keysContainer.appendChild(this._createKeys());

    this.elements.keys = this.elements.keysContainer.querySelectorAll(
      ".keyboard__key"
    );

    // Add to DOM
    this.elements.main.appendChild(this.elements.keysContainer);
    document.body.appendChild(this.elements.main);

    // Automatically use keyboard for elements with .use-keyboard-input
    document.querySelectorAll(".use-keyboard-input").forEach((element) => {
      element.addEventListener("focus", () => {
        this.open(element.value, (currentValue) => {
          element.value = currentValue;
          
        });
      });
    });
  },

  _createKeys() {
    const fragment = document.createDocumentFragment();
    const keyLayout = [
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "0",
      "_",
      "-",
     // "+",
      "q",
      "w",
      "e",
      "r",
      "t",
      "y",
      "u",
      "i",
      "o",
      "p",
      "@",
     // "(",
     // ")",
      "a",
      "s",
      "d",
      "f",
      "g",
      "h",
      "j",
      "k",
      "l",
      "backspace",
      //":",
      "caps",
      //"enter",
      // "done",
      "z",
      "x",
      "c",
      "v",
      "b",
      "n",
      "m",
      ".",
     // ",",
     // "?",
      "done",
     "spacer",

    ];

    // Creates HTML for an icon
    const createIconHTML = (icon_name) => {
      return `<i class="material-icons">${icon_name}</i>`;
    };

    keyLayout.forEach((key) => {
      const keyElement = document.createElement("button");
      const insertLineBreak =
        ["backspace", "@", "done", "-", "spacer_down"].indexOf(key) !== -1;

      // Add attributes/classes
      keyElement.setAttribute("type", "button");
      keyElement.classList.add("keyboard__key");

      switch (key) {
        case "backspace":
          keyElement.classList.add("keyboard__key--wide");
          keyElement.innerHTML = createIconHTML("backspace");

          keyElement.addEventListener("click", () => {
            this.properties.value = this.properties.value.substring(
              0,
              this.properties.value.length - 1
            );
            this._triggerEvent("oninput");
          });

          break;

        case "caps":
          keyElement.classList.add(
            "keyboard__key--more-wide",
            "keyboard__key--activatable"
          );
          keyElement.innerHTML = createIconHTML("keyboard_capslock");

          keyElement.addEventListener("click", () => {
            this._toggleCapsLock();
            keyElement.classList.toggle(
              "keyboard__key--active",
              this.properties.capsLock
            );
          });

          break;

        case "enter":
          keyElement.classList.add("keyboard__key--wide");
          keyElement.innerHTML = createIconHTML("keyboard_return");

          keyElement.addEventListener("click", () => {
            this.properties.value += "\n";
            this._triggerEvent("oninput");
          });

          break;

        case "space":
          keyElement.classList.add("keyboard__key--extra-wide");
          keyElement.innerHTML = createIconHTML("space_bar");

          keyElement.addEventListener("click", () => {
            this.properties.value += " ";
            this._triggerEvent("oninput");
          });

          break;

        case "done":
          keyElement.classList.add(
            "keyboard__key--more-wide",
            "keyboard__key--dark"
          );
          // keyElement.innerHTML = createIconHTML("check_circle");
          keyElement.innerHTML = "DONE";

          keyElement.addEventListener("click", () => {
            this.close();
            this._triggerEvent("onclose");
          });

          break;

          case "spacer":
          keyElement.classList.add("keyboard__key--spacer");

          break;
          case "spacer_down":
          keyElement.classList.add("keyboard__key--spacer");

          break;

        default:
          keyElement.textContent = key.toLowerCase();

          keyElement.addEventListener("click", () => {
            this.properties.value += this.properties.capsLock
              ? key.toUpperCase()
              : key.toLowerCase();
            this._triggerEvent("oninput");
          });

          break;
      }

      fragment.appendChild(keyElement);

      if (insertLineBreak) {
        fragment.appendChild(document.createElement("br"));
      }
    });

    return fragment;
  },

  _triggerEvent(handlerName) {
    if (typeof this.eventHandlers[handlerName] == "function") {
      this.eventHandlers[handlerName](this.properties.value);
    }
  },

  _toggleCapsLock() {
    this.properties.capsLock = !this.properties.capsLock;

    for (const key of this.elements.keys) {
      if (key.childElementCount === 0) {
        key.textContent = this.properties.capsLock
          ? key.textContent.toUpperCase()
          : key.textContent.toLowerCase();
      }
    }
  },

  open(initialValue, oninput, onclose) {
    this.properties.value = initialValue || "";
    this.eventHandlers.oninput = oninput;
    this.eventHandlers.onclose = onclose;
    this.elements.main.classList.remove("keyboard--hidden");
  },

  close() {
    this.properties.value = "";
    this.eventHandlers.oninput = oninput;
    this.eventHandlers.onclose = onclose;
    this.elements.main.classList.add("keyboard--hidden");
  },
  };

  const KeyboardNumber = {
  elements: {
    main: null,
    keysContainer: null,
    keys: [],
  },

  eventHandlers: {
    oninput: null,
    onclose: null,
  },

  properties: {
    value: "",
    capsLock: false,
  },

  init() {
    // Create main elements
    this.elements.main = document.createElement("div");
    this.elements.keysContainer = document.createElement("div");

    // Setup main elements
    this.elements.main.classList.add("keyboards", "keyboards--hidden");
    this.elements.keysContainer.classList.add("keyboards__keys");
    this.elements.keysContainer.appendChild(this._createKeys());

    this.elements.keys = this.elements.keysContainer.querySelectorAll(
      ".keyboards__key"
    );

    // Add to DOM
    this.elements.main.appendChild(this.elements.keysContainer);
    document.body.appendChild(this.elements.main);

    // Automatically use keyboard for elements with .use-keyboard-input
    document.querySelectorAll(".use-keyboards-inputnumber").forEach((element) => {
      element.addEventListener("focus", () => {
        this.open(element.value, (currentValue) => {
          element.value = currentValue;

          
        // const inputs = document.querySelectorAll("input");
        // if (!currentValue) {
        //   inputs[key -1].value = null;
        //   inputs[key -1].focus();
        //   key--
        //   tmp.pop()
        // } else {
        //   inputs[key].value = currentValue[currentValue.length -1];
        //   tmp.push(currentValue[currentValue.length -1])
        //   key++
        //   inputs[key].focus();
        // }
        });
      });
    });
  },

  _createKeys() {
    const fragment = document.createDocumentFragment();
    const keyLayout = [
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "0",
      "backspace",
      "done",

    ];

    // Creates HTML for an icon
    const createIconHTML = (icon_name) => {
      return `<i class="material-icons">${icon_name}</i>`;
    };

    keyLayout.forEach((key) => {
      const keyElement = document.createElement("button");
      const insertLineBreak =
        ["backspace", ")", "enter", "?"].indexOf(key) !== -1;

      // Add attributes/classes
      keyElement.setAttribute("type", "button");
      keyElement.classList.add("keyboards__key");

      switch (key) {
        case "backspace":
          keyElement.classList.add("keyboards__key--wides");
          keyElement.innerHTML = createIconHTML("backspace");

          keyElement.addEventListener("click", () => {
            this.properties.value = this.properties.value.substring(
              0,
              this.properties.value.length - 1
            );
            this._triggerEvent("oninput");
          });

          break;

        case "caps":
          keyElement.classList.add(
            "keyboards__key--wide",
            "keyboards__key--activatable"
          );
          keyElement.innerHTML = createIconHTML("keyboards_capslock");

          keyElement.addEventListener("click", () => {
            this._toggleCapsLock();
            keyElement.classList.toggle(
              "keyboards__key--active",
              this.properties.capsLock
            );
          });

          break;

        case "enter":
          keyElement.classList.add("keyboards__key--wide");
          keyElement.innerHTML = createIconHTML("keyboards_return");

          keyElement.addEventListener("click", () => {
            this.properties.value += "\n";
            this._triggerEvent("oninput");
          });

          break;

        case "space":
          keyElement.classList.add("keyboards__key--extra-wide");
          keyElement.innerHTML = createIconHTML("space_bar");

          keyElement.addEventListener("click", () => {
            this.properties.value += " ";
            this._triggerEvent("oninput");
          });

          break;

        case "done":
          keyElement.classList.add(
            "keyboards__key--wide",
            "keyboards__key--dark"
          );
          // keyElement.innerHTML = createIconHTML("check_circle");
          keyElement.innerHTML = "DONE";

          keyElement.addEventListener("click", () => {
            this.close();
            this._triggerEvent("onclose");
          });

          break;

        default:
          keyElement.textContent = key.toLowerCase();

          keyElement.addEventListener("click", () => {
            this.properties.value += this.properties.capsLock
              ? key.toUpperCase()
              : key.toLowerCase();
            this._triggerEvent("oninput");
          });

          break;
      }

      fragment.appendChild(keyElement);

      if (insertLineBreak) {
        fragment.appendChild(document.createElement("br"));
      }
    });

    return fragment;
  },

  _triggerEvent(handlerName) {
    if (typeof this.eventHandlers[handlerName] == "function") {
      this.eventHandlers[handlerName](this.properties.value);
    }
  },

  _toggleCapsLock() {
    this.properties.capsLock = !this.properties.capsLock;

    for (const key of this.elements.keys) {
      if (key.childElementCount === 0) {
        key.textContent = this.properties.capsLock
          ? key.textContent.toUpperCase()
          : key.textContent.toLowerCase();
      }
    }
  },

  open(initialValue, oninput, onclose) {
    this.properties.value = initialValue || "";
    this.eventHandlers.oninput = oninput;
    this.eventHandlers.onclose = onclose;
    this.elements.main.classList.remove("keyboards--hidden");
  },

  close() {
    this.properties.value = "";
    this.eventHandlers.oninput = oninput;
    this.eventHandlers.onclose = onclose;
    this.elements.main.classList.add("keyboards--hidden");
  },
};
//lifecycle hooks
onMounted(() => {
  Keyboard.init();
  KeyboardNumber.init();
});

const goToSignatureCreate = () => {
  router.push({ name: "TandaTangan" });
  Keyboard.close()
  KeyboardNumber.close()
}

const redirectGuard = () => {
  window.location.href = "#";
}

const back = () => {
  const inputs = document.querySelectorAll("input");
    router.push({ name: "AmbilFoto" })
    Keyboard.close()
    KeyboardNumber.close()

}

const cancel = () => {
  const inputs = document.querySelectorAll("input");
  
    Keyboard.close()
    router.push({name: 'StartingPage'})
    KeyboardNumber.close()
}

</script>

<script>
export default {
  name:"BiodateKTP",
  data() {
    return {
      email:"",
      phoneNumber:""
    }
  }
}

</script>


<template>
  <div class="command">
      <button class="navigation btnbening" @click="back">Kembali</button>
      <button class="navigation btnbening" @click="cancel">Batal</button>
  </div>

  <div class="profile-content">
    <div class="profile-title">Halo, <span class="ktpName">{{$store.state.ektpResults.nama}}</span></div>
    <div class="profile-details">
      <div class="question">
        <div class="question-item">NIK</div>
        <div class="question-item">Jenis Kelamin</div>
        <div class="question-item">Alamat</div>
        <div class="question-item">Tanggal Lahir</div>
      </div>
      <div class="value">
        <div class="value-item">: {{$store.state.ektpResults.nik}}</div>
        <div class="value-item">: {{$store.state.ektpResults.jenisKelamin}}</div>
        <div class="value-item">: {{$store.state.ektpResults.alamat}}</div>
        <div class="value-item">: {{$store.state.ektpResults.tanggalLahir}}</div>
      </div>
      <div class="imgs">
        <img v-bind:src="$store.state.photoResults"  style="width: 100%;" />
      </div>
    </div>
    <div class="profile-separator">
      <div class="profile-separator-title">Mohon Isi Data Pelengkap</div>
    </div>
    <div class="profile-additional">
      <div class="question-additional">
        <div class="question-item-additional">
          No. Telp <span class="question-item-span">*</span>
        </div>
        <div class="value-item-additional">
          <input placeholder="Sentuh disini untuk menampilkan keyboard screen"  class="use-keyboards-inputnumber value-input" v-model="phoneNumber"/>
        </div>
      </div>
    </div>
    <div class="profile-additional">
      <div class="question-additional">
        <div class="question-item-additional">
          Alamat Email <span class="question-item-span">*</span>
        </div>
        <div class="value-item-additional">
          <input :placeholder="message" class="use-keyboard-input value-input" v-model="email" />
        </div>
      </div>
    </div>
    <div>
      <button @click="goToSignatureCreate()">Lanjutkan</button>
    </div>
  </div>

  <!-- Modal  -->
  <div id="open-modal" class="modal-window">
    <div class="modal-content">
      <div class="header-circle">
        <div class="dot">
          <img src="../../../src/assets/error-icon.png" />
        </div>
      </div>
      <div class="failed-scan-content">
        <div class="content-text">
          <div>EMAIL DAN NOMOR TELEPON WAJIB DIISI</div>
          <div class="content-text-margin">Harap lengkapi email dan nomor telepon untuk proses selanjutnya</div>
        </div>
        <div>
          <a href="#" title="Close">
            <button class="button-failed-scan" @click="redirectGuard">OK</button>
          </a>
        </div>
      </div>
    </div>
   </div>
  <!-- end modal -->
</template>



<style scoped>
@import '../../assets/keyboard.css';

.profile-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 35px;
  flex-grow: 1;
  margin-bottom: 84px;

  font-family: "Lato", sans-serif;
  font-weight: 400;
  font-size: 25px;
  line-height: 30px;
  text-align: center;
  color: var(--primary-blue);
}

.profile-title {
  font-weight: 500;
  font-size: 30px;
  line-height: 54px;
  text-align: center;
  color: var(--primary-blue);
  margin-bottom: 12px;
}

.profile-details {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: left;
  width: 70%;
}

.profile-separator {
  width: 70%;
  margin-top: 20px;
}

.profile-separator-title {
  width: 42.5%;
  margin-bottom: 10px;
}

.profile-additional {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: left;
  width: 90%;
}

.question {
  width: 20%;
}

.question-additional {
  width: 70%;
  display: flex;
  margin-bottom: 20px;
}

.question-item {
  margin-bottom: 10px;
}

.question-item-additional {
  height: 50px;
  width: 20%;
  display: flex;
  align-items: center;
}

.question-item-span {
  color: #cc0095;
}

.value {
  width: 40%;
}

.value-additional {
  width: 50%;
}

.value-item {
  margin-bottom: 10px;
}

.value-item-additional {
  height: 50px;
  width: 50%;
  margin-bottom: 0px;

}

.value-input {
  border: 1px solid #cbcbcb;
  padding: 3px 20px;
  font-weight: 300;
  font-size: 21px;
  line-height: 32px;
  color: #3b3b3b;
  border-radius: 10px;
  width: 100%;
}

.imgs {
  width: 230px;
  height: 180px;
  overflow: hidden;
  background-color: transparent;
  padding: 5px;
  /* box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1) */
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);

}


button {
  height: 83px;
  width: 269px;
  background: var(--primary-blue);
  font-weight: 200;
  font-size: 28px;
  line-height: 34px;
  color: var(--primary-white);
  filter: drop-shadow(0px 3px 16px rgba(124, 124, 124, 0.133));
  border: none;
  border-radius: 7px;
  margin-top: 30px;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
}

.ktpName {
  color: var(--primary-bri-secondary);
}

.btnbening {
  background-color: transparent!important;
  color: var(--primary-blue);
}
</style>