<script setup>
import { onMounted } from "vue";
import { useRouter } from "vue-router";
import Navigation from "../Navigation.vue";

const router = useRouter();

//lifecycle hooks
let key = 0
let tmp = [] //<-- value from keyboard collected


const Keyboard = {
  elements: {
    main: null,
    keysContainer: null,
    keys: [],
  },

  eventHandlers: {
    oninput: null,
    onclose: null,
  },

  properties: {
    value: "",
    capsLock: false,
  },

  init() {
    // Create main elements
    this.elements.main = document.createElement("div");
    this.elements.keysContainer = document.createElement("div");

    // Setup main elements
    this.elements.main.classList.add("keyboards", "keyboards--hidden");
    this.elements.keysContainer.classList.add("keyboards__keys");
    this.elements.keysContainer.appendChild(this._createKeys());

    this.elements.keys = this.elements.keysContainer.querySelectorAll(
      ".keyboards__key"
    );

    // Add to DOM
    this.elements.main.appendChild(this.elements.keysContainer);
    document.body.appendChild(this.elements.main);

    // Automatically use keyboard for elements with .use-keyboard-input
    document.querySelectorAll(".use-keyboards-inputnumber").forEach((element) => {
      element.addEventListener("focus", () => {
        this.open(element.value, (currentValue) => {
          // element.value = currentValue;
          
        const inputs = document.querySelectorAll("input");
        if (!currentValue) {
          inputs[key -1].value = null;
          inputs[key -1].focus();
          key--
          tmp.pop()
        } else {
          inputs[key].value = currentValue[currentValue.length -1];
          tmp.push(currentValue[currentValue.length -1])
          key++
          inputs[key].focus();
        }
        });
      });
    });
  },

  _createKeys() {
    const fragment = document.createDocumentFragment();
    const keyLayout = [
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "0",
      "backspace",
      "done",

    ];

    // Creates HTML for an icon
    const createIconHTML = (icon_name) => {
      return `<i class="material-icons">${icon_name}</i>`;
    };

    keyLayout.forEach((key) => {
      const keyElement = document.createElement("button");
      const insertLineBreak =
        ["backspace", ")", "enter", "?"].indexOf(key) !== -1;

      // Add attributes/classes
      keyElement.setAttribute("type", "button");
      keyElement.classList.add("keyboards__key");

      switch (key) {
        case "backspace":
          keyElement.classList.add("keyboards__key--wides");
          keyElement.innerHTML = createIconHTML("backspace");

          keyElement.addEventListener("click", () => {
            this.properties.value = this.properties.value.substring(
              0,
              this.properties.value.length - 1
            );
            this._triggerEvent("oninput");
          });

          break;

        case "caps":
          keyElement.classList.add(
            "keyboards__key--wide",
            "keyboards__key--activatable"
          );
          keyElement.innerHTML = createIconHTML("keyboards_capslock");

          keyElement.addEventListener("click", () => {
            this._toggleCapsLock();
            keyElement.classList.toggle(
              "keyboards__key--active",
              this.properties.capsLock
            );
          });

          break;

        case "enter":
          keyElement.classList.add("keyboards__key--wide");
          keyElement.innerHTML = createIconHTML("keyboards_return");

          keyElement.addEventListener("click", () => {
            this.properties.value += "\n";
            this._triggerEvent("oninput");
          });

          break;

        case "space":
          keyElement.classList.add("keyboards__key--extra-wide");
          keyElement.innerHTML = createIconHTML("space_bar");

          keyElement.addEventListener("click", () => {
            this.properties.value += " ";
            this._triggerEvent("oninput");
          });

          break;

        case "done":
          keyElement.classList.add(
            "keyboards__key--wide",
            "keyboards__key--dark"
          );
          // keyElement.innerHTML = createIconHTML("check_circle");
          keyElement.innerHTML = "DONE";

          keyElement.addEventListener("click", () => {
            this.close();
            this._triggerEvent("onclose");
          });

          break;

        default:
          keyElement.textContent = key.toLowerCase();

          keyElement.addEventListener("click", () => {
            this.properties.value += this.properties.capsLock
              ? key.toUpperCase()
              : key.toLowerCase();
            this._triggerEvent("oninput");
          });

          break;
      }

      fragment.appendChild(keyElement);

      if (insertLineBreak) {
        fragment.appendChild(document.createElement("br"));
      }
    });

    return fragment;
  },

  _triggerEvent(handlerName) {
    if (typeof this.eventHandlers[handlerName] == "function") {
      this.eventHandlers[handlerName](this.properties.value);
    }
  },

  _toggleCapsLock() {
    this.properties.capsLock = !this.properties.capsLock;

    for (const key of this.elements.keys) {
      if (key.childElementCount === 0) {
        key.textContent = this.properties.capsLock
          ? key.textContent.toUpperCase()
          : key.textContent.toLowerCase();
      }
    }
  },

  open(initialValue, oninput, onclose) {
    this.properties.value = initialValue || "";
    this.eventHandlers.oninput = oninput;
    this.eventHandlers.onclose = onclose;
    this.elements.main.classList.remove("keyboards--hidden");
  },

  close() {
    this.properties.value = "";
    this.eventHandlers.oninput = oninput;
    this.eventHandlers.onclose = onclose;
    this.elements.main.classList.add("keyboards--hidden");
  },
};

onMounted(() => {
  Keyboard.init();

  const inputs = document.querySelectorAll("input");
  inputs[0].focus()
  const codeBlock = document.getElementById("code-block");
  const code = document.getElementById("code");
  const form = document.querySelector("form");

  inputs.forEach((input, key) => {
    if (key !== 0) {
      input.addEventListener("click", function () {
        inputs[0].focus();
      });
    }
    input.addEventListener("keyup", function () {
      if (input.value) {
        if (key === 9) {
          // Last one tadaa
          alert("yuhuuuu")
          const userCode = [...inputs].map((input) => input.value).join("");
          codeBlock.classList.remove("hidden");
          code.innerText = userCode;
        } else {
          inputs[key + 1].focus();
        }
      }
    });
  });
});


const redirectGuard = () => {
  window.location.href = "#";
}

const back = () => {
  const inputs = document.querySelectorAll("input");
  for (let i = 0; i < inputs.length; i++) {
    inputs[i].value = null;
  }
    key = 0;
    tmp = [];
    // inputs[0].focus();
    router.push({ name: "CKAmbilFoto" })
    Keyboard.close()
}

const cancel = () => {
  const inputs = document.querySelectorAll("input");
  for (let i = 0; i < inputs.length; i++) {
    inputs[i].value = null;
  }
    key = 0;
    tmp = [];
    // inputs[0].focus();
    // router.push({ name: "CKAmbilFoto" })
    Keyboard.close()
    router.push({name: 'StartingPage'})
}

const next = () => {
  if (tmp.length < 10) {
    window.location.href = "#open-modal";
  } else {
      console.log("selesai")
      const inputs = document.querySelectorAll("input");

      for (let i = 0; i < inputs.length; i++) {
        inputs[i].value = null;
        }
        key = 0;
        tmp = [];
        inputs[0].focus();
        router.push({ name: "CKPinInput" })
        Keyboard.close()
  }

}

</script>

<template>
  <div class="command">
      <button class="navigation" @click="back">Kembali</button>
      <button class="navigation" @click="cancel">Batal</button>
  </div>
  <div class="input-rek-content">
    <div>
      <div class="input-rek-title">Masukkan Nomor Rekening Anda</div>
      <div>
        <form>
          <input type="text" maxlength="1" class="use-keyboards-inputnumber" />
          <input type="text" maxlength="1" class="use-keyboards-inputnumber" />
          <input type="text" maxlength="1" class="use-keyboards-inputnumber" />
          <input type="text" maxlength="1" class="use-keyboards-inputnumber" />
          <div>-</div>
          <input type="text" maxlength="1" class="use-keyboards-inputnumber" />
          <input type="text" maxlength="1" class="use-keyboards-inputnumber" />
          <input type="text" maxlength="1" class="use-keyboards-inputnumber" />
          <input type="text" maxlength="1" class="use-keyboards-inputnumber" />
          <div>-</div>
          <input type="text" maxlength="1" class="use-keyboards-inputnumber" />
          <input type="text" maxlength="1" class="use-keyboards-inputnumber" />
        </form>
      </div>
      <div class="input-rek-titlenorek">Gunakan screen keyboard untuk mengisi nomor rekening</div>
      <!-- <div class="show-button">Show</div> -->
    </div>

    <div>
      <button @click="next">LANJUTKAN</button>
    </div>
  </div>
  <!-- Modal  -->
  <div id="open-modal" class="modal-window">
    <div class="modal-content">
      <div class="header-circle">
        <div class="dot">
          <img src="../../../src/assets/error-icon.png" />
        </div>
      </div>
      <div class="failed-scan-content">
        <div class="content-text">
          <div>INPUT BELUM SELESAI</div>
          <div class="content-text-margin">Harap isi 10 digit nomor rekening anda</div>
        </div>
        <div>
          <a href="#" title="Close">
            <button class="button-failed-scan" @click="redirectGuard">OK</button>
          </a>
        </div>
      </div>
    </div>
   </div>
  <!-- end modal -->
</template>

<style>
@import '../../assets/keyboardNumber.css';

.input-rek-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 75px;
  flex-grow: 1;
  margin-bottom: 84px;
  justify-content: space-between;

  font-family: "Lato", sans-serif;
  font-weight: 400;
  font-size: 25px;
  line-height: 30px;
  text-align: center;
  color: var(--primary-blue);
}

.input-rek-title {
  margin-bottom: 32px;
  font-weight: 400;
  font-size: 25px;
  line-height: 30px;
  color: var(--primary-blue);
}

.input-rek-titlenorek {
  margin-top: 32px;
  margin-bottom: 32px;
  font-weight: 400;
  font-size: 25px;
  line-height: 30px;
  color: var(--primary-blue);
}

.show-button {
  text-align: right;
  margin-top: 28px;
  font-weight: 700;
  font-size: 26px;
  line-height: 35px;
  color: #4a4545;
}

button {
  height: 83px;
  width: 269px;
  background: var(--primary-blue);
  font-weight: 700;
  font-size: 28px;
  line-height: 34px;
  color: var(--primary-white);
  filter: drop-shadow(0px 3px 16px rgba(124, 124, 124, 0.133));
  border: none;
  border-radius: 10px;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);

}

form {
  display: flex;
  justify-content: center;
  align-items: center;
}
input {
  margin: 0 0.5rem;
  padding: 0.5rem;
  border: none;
  border-bottom: 2px solid #4155aa;
  width: 50px;
  height: 50px;
  text-align: center;
  font-size: 3rem;
}
.special {
  margin-top: 2rem;
  font-size: 2rem;
  opacity: 1;
  visibility: visible;
  transition: all 0.3s ease;
}

</style>