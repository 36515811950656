import store from '../../store/index'
import route from "../../router"
let port = null;


export default {

  data() {
    return {
      count: 2,
      nama:"andi",
    }
  },
  methods: {

    async getPort() {
      // Promp user to select any serial port
      let filters = [{ usbVendorId: 1659, usbProductId: 8963 }];
      port = await navigator.serial.requestPort({ filters });

      const { usbProductId, usbVendorId } = port.getInfo();

      await port.open({ baudRate: 115200 });

      // Listen to serial data
      const reader = port.readable.getReader();
      // console.log(reader)
      let tmp = [];
      let dataLenh = 0;
      let dataLenl = 0;
      let data = [];

      while (true) {
        const { value, done } = await reader.read();
        // console.log(value)
        if (done) {
          reader.releaseLock();
          break;
        }
        for (let i = 0; i < value.length; i++) {
          tmp.push(value[i]);
          if (tmp.length == 4) {
            dataLenh = tmp[2];
            dataLenl = tmp[3];
          }

          if (tmp.length >= 5 && data.length <= dataLenh * 255 + dataLenl) {
            data.push(value[i]);
          }

          if (tmp.length == 4 + dataLenh * 255 + dataLenl + 3) {
            let sw1 = this.decimalToHexString(tmp[tmp.length - 3]);
            let sw2 = this.decimalToHexString(tmp[tmp.length - 2]);

            data.pop();
            this.prosesData(tmp, data, sw1, sw2);
            tmp = [];
            data = [];
          }
        }
      }
    },

    prosesData(commandData, data, sw1, sw2) {
      console.log(commandData,'-->')
      // buat validasi atas global response dari sw1 dan sw2
      let newFormat = [];
      if (commandData[1] == 185) {
        // 185 is 0xB9
        for (let i = 0; i < commandData.length; i++) {
          newFormat.push(this.decimalToHexString(commandData[i]));
        }
        if (localStorage.getItem('mode') == 'BR') {
          route.push({name: "JanganPindahkanKtp"})
        } else if (localStorage.getItem('mode') == 'GK') {
          route.push({name: "GKJanganPindahkanKtp"})
        } else if (localStorage.getItem('mode') == 'CK') {
          route.push({name: "CKJanganPindahkanKtp"})
        } else {
          alert("error")
        }
        

      } else if (commandData[1] == 181 && commandData.length <= 7) {
        // 181 is 0xB5 // ganti datalent
        for (let i = 0; i < commandData.length; i++) {
          newFormat.push(this.decimalToHexString(commandData[i]));
        }
        window.location.href = "#open-modal";
      } else if (commandData[1] == 181 && commandData.length > 7) {
        // 181 is 0xB5
        for (let i = 0; i < commandData.length; i++) {
          newFormat.push(this.decimalToHexString(commandData[i]));
        }

        let kodeJari = newFormat[4];
        let kodeJariCadangan = newFormat[5];

        this.bacaJari();
        store.dispatch('getFingerPointed', kodeJari)


        if (localStorage.getItem('mode') == 'BR') {
          route.push({name: "LetakanJari"})
        } else if (localStorage.getItem('mode') == 'GK') {
          route.push({name: "GKLetakanJari"})
        } else if (localStorage.getItem('mode') == 'CK') {
          route.push({name: "CKLetakanJari"})
        } else {
          alert("error")
        }




      } else if (commandData[1] == 182) {
        // 182 is 0XB6
        // no need response
      } else if (commandData[1] == 183 && commandData.length > 7) {
        // 183 is 0XB7
        for (let i = 0; i < commandData.length; i++) {
          newFormat.push(this.decimalToHexString(commandData[i]));
        }
        // console.log(newFormat)

        for (let j = 0; j < newFormat.length; j++) {
          if (newFormat[j] == "90" && newFormat[j + 1] == 0) {
            this.sendBioData();
            if (localStorage.getItem('mode') == 'BR') {
              route.push({name: "AmbilFoto"})
            } else if (localStorage.getItem('mode') == 'GK') {
              route.push({name: "GKAmbilFoto"})
            } else if (localStorage.getItem('mode') == 'CK') {
              route.push({name: "CKAmbilFoto"})
            } else {
              alert("error")
            }
            
          } else if (newFormat[j] == "6F" && newFormat[j + 1] == 0) {
            console.log("Match Jari gagal");
            window.location.href = "#open-modal";
          } else if (newFormat[j] == "6A" && newFormat[j + 1] == 92) {
            console.log("MatcH jari gagal , terlalu banyak percobaan");
          }
        }

      } else if (commandData[1] == 177) {
        let biodata = {
          nik: "",
          nama: "",
          alamat: "",
          rt: "",
          rw: "",
          tempatLahir: "",
          tanggalLahir: "",
          kecamatan: "",
          kelurahan: "",
          kabupaten: "",
          provinsi: "",
          jenisKelamin: "",
          agama: "",
          statusPerkawinan: "",
          pekerjaan: "",
          kewarganegaraan: "",
          berlakuHingga: "",
          rawData: "",
        };
        let str = "";
        for (let i = 0; i < data.length; i++) {
          if (String.fromCharCode(data[i]) !== '"') {
            str += String.fromCharCode(data[i]);
            newFormat.push(this.decimalToHexString(commandData[i]));
          }
        }

        let newStr = str.split(",");

        biodata.nik = newStr[0];
        biodata.nama = newStr[13];
        biodata.alamat = newStr[1];
        biodata.rt = newStr[2];
        biodata.rw = newStr[3];
        biodata.tempatLahir = newStr[4];
        biodata.tanggalLahir = newStr[14];
        biodata.kecamatan = newStr[5];
        biodata.kelurahan = newStr[6];
        biodata.kabupaten = newStr[7];
        biodata.provinsi = newStr[15];
        biodata.jenisKelamin = newStr[8];
        biodata.agama = newStr[10];
        biodata.statusPerkawinan = newStr[11];
        biodata.pekerjaan = newStr[12];
        biodata.kewarganegaraan = newStr[19];
        biodata.berlakuHingga = newStr[16];

        store.dispatch('getBiodata', biodata)

      } else if (commandData[1] == 162) {
        console.log(commandData, "command Data");
        console.log(data, "data");
      } else if (commandData[1] == 163) {
        console.log(commandData, "command Data");
        console.log(data, "data");
      }

      data = [];
    },

    // Command EKTP
    getLRC(bytes, start, end) {
      let LRC = 0;
      for (let i = start; i <= end; i++) {
        LRC = bytes[i] ^ LRC;
      }

      return LRC;
    },

    EKTPCommand_Homescreen() {
      const command = new Uint8Array(5);
      command[0] = 0x3f; //stx
      command[1] = 0xb9;
      command[2] = 0x00; //LenH
      command[3] = 0x00; //LenL
      command[4] = this.getLRC(command, 1, 3 + (command[2] * 256 + command[3]));

      return command;
    },

    EKTPCommand_Reboot() {
      const command = new Uint8Array(6);
      command[0] = 0x3f; //stx
      command[1] = 0x95; //INS //B9 B5 B6 B7
      command[2] = 0x00; //LenH
      command[3] = 0x01; //LenL
      command[4] = 0x01; //SW1 <--- DATA1
      command[5] = this.getLRC(command, 1, 3 + (command[2] * 256 + command[3]));

      return command;
    },

    EKTPCommand_Read() {
      const command = new Uint8Array(5);
      command[0] = 0x3f; //stx
      command[1] = 0xb5; //INS //B9 B5 B6 B7
      command[2] = 0x00; //LenH
      command[3] = 0x00; //LenL
      command[4] = this.getLRC(command, 1, 3 + (command[2] * 256 + command[3]));

      return command;
    },

    EKTPCommand_Finger() {
      const command = new Uint8Array(7);
      command[0] = 0x3f; //stx
      command[1] = 0xb6; //INS //B9 B5 B6 B7
      command[2] = 0x00; //LenH
      command[3] = 0x02; //LenL
      command[4] = 0x02; //SW1 <---- SALAAH HARUSNYA DATA1
      command[5] = 0x00; //SW2 <---- DATA 2
      command[6] = this.getLRC(command, 1, 3 + (command[2] * 256 + command[3]));
      return command;
    },

    EKTPCommand_MatchFinger(finger) {
      const command = new Uint8Array(7);
      command[0] = 0x3f; //stx
      command[1] = 0xb7; //INS //B9 B5 B6 B7
      command[2] = 0x00; //LenH
      command[3] = 0x02; //LenL
      command[4] = 0x02; //SW1 <--- DATA1
      command[5] = 0x01; //SW2 <--- DATA2
      command[6] = this.getLRC(command, 1, 3 + (command[2] * 256 + command[3]));
      return command;
    },

    EKTPCommand_BioData() {
      const command = new Uint8Array(5);
      command[0] = 0x3f; //stx
      command[1] = 0xb1; //INS //B9 B5 B6 B7
      command[2] = 0x00; //LenH
      command[3] = 0x00; //LenL
      command[4] = this.getLRC(command, 1, 3 + (command[2] * 256 + command[3]));

      return command;
    },

    EKTPCommand_GetDataPhoto() {
      const command = new Uint8Array(5);
      command[0] = 0x3f; //stx
      command[1] = 0xa2; //INS //B9 B5 B6 B7
      command[2] = 0x00; //LenH
      command[3] = 0x00; //LenL
      command[4] = this.getLRC(command, 1, 3 + (command[2] * 256 + command[3]));

      return command;
    },

    EKTPCommand_GetPhoto() {
      const command = new Uint8Array(5);
      command[0] = 0x3f; //stx
      command[1] = 0xa3; //INS //B9 B5 B6 B7
      command[2] = 0x00; //LenH
      command[3] = 0x00; //LenL
      command[4] = this.getLRC(command, 1, 3 + (command[2] * 256 + command[3]));

      return command;
    },

    hexToByte(hex) {
      const key = "0123456789abcdef";
      let newBytes = [];
      let currentChar = 0;
      let currentByte = 0;
      for (let i = 0; i < hex.length; i++) {
        // Go over two 4-bit hex chars to convert into one 8-bit byte
        currentChar = key.indexOf(hex[i]);
        if (i % 2 === 0) {
          // First hex char
          currentByte = currentChar << 4; // Get 4-bits from first hex char
        }
        if (i % 2 === 1) {
          // Second hex char
          currentByte += currentChar; // Concat 4-bits from second hex char
          newBytes.push(currentByte); // Add byte
          // input  => hexToByte('68656c6c6f')
          // output => [104, 101, 108, 108, 111]
        }
      }
      return new Uint8Array(newBytes);
    },

    //   decoder = new TextDecoder("UTF-8")

    toString(data) {
      const extraByteMap = [1, 1, 1, 1, 2, 2, 3, 0];
      var count = data.length;
      var str = "";

      for (var index = 0; index < count; ) {
        var ch = data[index++];
        if (ch & 0x80) {
          var extra = extraByteMap[(ch >> 3) & 0x07];
          if (!(ch & 0x40) || !extra || index + extra > count) return null;

          ch = ch & (0x3f >> extra);
          for (; extra > 0; extra -= 1) {
            var chx = data[index++];
            if ((chx & 0xc0) != 0x80) return null;

            ch = (ch << 6) | (chx & 0x3f);
          }
        }

        str += String.fromCharCode(ch);
      }

      return str;
    },

    convert(int) {
      const code = "A".charCodeAt(0);
      console.log(code); // 👉️ 65

      return String.fromCharCode(code + int);
    },

    // Main Function
    async homescreen() {
      try {
        const writer = port.writable.getWriter();
        await writer.write(this.EKTPCommand_Homescreen());
  
        writer.releaseLock();
      } catch (error) {
        window.location.href = "#open-modalektpnotfound";

      }
    },

    async bacaKtp() {
      const writer = port.writable.getWriter();
      await writer.write(this.EKTPCommand_Read());

      writer.releaseLock();
    },

    async sendBioData() {
      // _biodatas.clear();
      const writer = port.writable.getWriter();
      await writer.write(this.EKTPCommand_BioData());

      writer.releaseLock();
    },

    async requestPhoto() {
      const writer = port.writable.getWriter();
      await writer.write(this.EKTPCommand_GetDataPhoto());

      writer.releaseLock();
    },

    async getPhoto() {
      const writer = port.writable.getWriter();
      await writer.write(this.EKTPCommand_GetPhoto());

      writer.releaseLock();
    },

    async resetAlat() {
      const writer = port.writable.getWriter();
      await writer.write(this.EKTPCommand_Reboot());

      writer.releaseLock();
    },

    async bacaJari(finger) {
      const writer = port.writable.getWriter();
      await writer.write(this.EKTPCommand_Finger());
      writer.releaseLock();
    },

    async matchJari(finger) {
      const writer = port.writable.getWriter();
      await writer.write(this.EKTPCommand_MatchFinger());

      writer.releaseLock();
    },

    decimalToHexString(number) {
      if (number < 0) {
        number = 0xffffffff + number + 1;
      }

      return number.toString(16).toUpperCase();
    },
  },
};
