<script setup>
import { useRouter } from "vue-router";
const router = useRouter();
</script>

<script>
import store from "../../../src/store";
import Navigation from "../Navigation.vue";
export default {
  name: "Ambil Foto",
  data() {
    return {
      isCameraOpen: false,
      isPhotoTaken: false,
      isShotPhoto: false,
      isLoading: false,
      link: "#",
    };
  },
  mounted() {
    if (this.isCameraOpen) {
      this.isCameraOpen = false;
      this.isPhotoTaken = false;
      this.isShotPhoto = false;
      this.stopCameraStream();
    } else {
      this.isCameraOpen = true;
      this.createCameraElement();
    }
  },
  unmounted() {
    this.isCameraOpen = false;
    this.isPhotoTaken = false;
    this.isShotPhoto = false;
  },
  methods: {
    toggleCamera() {
      if (this.isCameraOpen) {
        this.isCameraOpen = false;
        this.isPhotoTaken = false;
        this.isShotPhoto = false;
        this.stopCameraStream();
      } else {
        this.isCameraOpen = true;
        this.createCameraElement();
      }
    },
    createCameraElement() {
      this.isLoading = true;
      const constraints = (window.constraints = {
        audio: false,
        video: true,
      });
      navigator.mediaDevices
        .getUserMedia(constraints)
        .then((stream) => {
          this.isLoading = false;
          this.$refs.camera.srcObject = stream;
        })
        .catch((error) => {
          this.isLoading = false;
          window.location.href = "#open-modal";
        });
    },
    stopCameraStream() {
      let tracks = this.$refs.camera.srcObject.getTracks();
      tracks.forEach((track) => {
        track.stop();
      });
    },
    takePhoto() {
      if (!this.isPhotoTaken) {
        this.isShotPhoto = true;
        const FLASH_TIMEOUT = 50;
        setTimeout(() => {
          this.isShotPhoto = false;
        }, FLASH_TIMEOUT);
      }
      this.isPhotoTaken = !this.isPhotoTaken;
      const context = this.$refs.canvas.getContext("2d");
      context.drawImage(this.$refs.camera, 0, 0, 450, 337.5);
    },
    goToBiodata() {
      const download = document.getElementById("downloadPhoto");
      const canvas = document
        .getElementById("photoTaken")
        .toDataURL("image/png");
      store.dispatch("getPhoto", canvas);
      this.stopCameraStream();
      this.$router.push({ name: "BiodataEktp" });
    },
    reTakePhoto() {
      // this.isCameraOpen = true;
      this.isPhotoTaken = false;
      this.isShotPhoto = false;
      // this.$router.push({ name: "AmbilFoto" });
    },
    back() {
      this.stopCameraStream();
      this.$router.push({ name: "LetakanJari" });
    },

    cancel() {
      this.stopCameraStream();
      this.$router.push({ name: "StartingPage" });
    },
  },
  components: { Navigation },
  };
</script>

<template>
  <div class="command">
    <button class="navigation btnbening" @click="back">Kembali</button>
    <button class="navigation btnbening" @click="cancel">Batal</button>
  </div>
  <div class="wrapper">
    <div class="ambil-foto-diri-content">
      <div v-if="!isPhotoTaken" class="ambil-foto-diri-title">
        Ambil Foto Diri
      </div>
      <div v-if="isPhotoTaken" class="ambil-foto-diri-title">
        Foto Berhasil dibuat
      </div>
      <!-- <div><img src="../assets/ambil-foto-diri-pre.png" /></div> -->
      <!--  -->
      <div class="camera-box" :class="{ flash: isShotPhoto }">
        <video
          v-show="!isPhotoTaken"
          ref="camera"
          :width="450"
          :height="337.5"
          autoplay
        ></video>

        <canvas
          v-show="isPhotoTaken"
          id="photoTaken"
          ref="canvas"
          :width="450"
          :height="337.5"
        ></canvas>

        <!-- <div v-if="isCameraOpen && !isLoading" class="camera-shoot">
            <button type="button" class="button" @click="takePhoto">
              <img
                src="https://img.icons8.com/material-outlined/50/000000/camera--v2.png"
              />
            </button>
          </div> -->
        <div v-if="isCameraOpen && !isPhotoTaken && !isLoading">
          Mohon untuk tegak kepala anda dengan menghadap kamera
        </div>
        <div v-if="isLoading">Sedang Mempersiapkan Kamera, Mohon Tunggu</div>

        <div style="margin-top: 100px">
          <button
            type="button"
            style="
              margin-right: 50px;
              background: var(--primary-white);
              color: var(--primary-blue);
            "
            @click="reTakePhoto"
            v-if="isCameraOpen && !isLoading && isPhotoTaken"
          >
            <span>Ambil Foto Ulang</span>
          </button>
          <button
            type="button"
            @click="takePhoto"
            v-if="isCameraOpen && !isLoading && !isPhotoTaken"
          >
            <span>Mulai</span>
          </button>
          <button
            type="button"
            @click="goToBiodata"
            v-if="isCameraOpen && !isLoading && isPhotoTaken"
          >
            <span>Lanjutkan</span>
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- Modal  -->
  <div id="open-modal" class="modal-window">
    <div class="modal-content">
      <div class="header-circle">
        <div class="dot">
          <img src="../../../src/assets/error-icon.png" />
        </div>
      </div>
      <div class="failed-scan-content">
        <div class="content-text">
          <div>SISTEM GAGAL MENGOPERASIKAN KAMERA</div>
          <div class="content-text-margin">
            Mohon maaf , proses pembukaan rekening terhenti dikarenakan
            kesalahan sistem
          </div>
        </div>
        <div>
          <a href="#" title="Close">
            <button
              class="button-failed-scan"
              @click="router.push({ name: 'StartingPage' })"
            >
              OK
            </button>
          </a>
        </div>
      </div>
    </div>
  </div>
  <!-- end modal -->
</template>

<style scoped>
.wrapper {
  max-height: 100vh;
  overflow: hidden;
}

.camera-box {
  position: relative;
  top: 10px;
}
.camera-box video {
  margin-top: 10px;
  /* margin-bottom: 20px; */
  /* background-color: black; */
  border-radius: 7px;
}
.ambil-foto-diri-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 55px;
  flex-grow: 1;
  margin-bottom: 84px;
  justify-content: space-around;

  font-family: "Lato", sans-serif;
  font-weight: 400;
  font-size: 25px;
  line-height: 30px;
  text-align: center;
  color: var(--primary-blue);
}

.ambil-foto-diri-title {
  font-weight: 700;
  font-size: 25px;
  line-height: 30px;
  text-align: center;
  color: var(--primary-blue);
}

button {
  height: 83px;
  width: 269px;
  background: var(--primary-blue);
  font-weight: 200;
  font-size: 28px;
  line-height: 34px;
  color: var(--primary-white);
  filter: drop-shadow(0px 3px 16px rgba(124, 124, 124, 0.133));
  border: none;
  border-radius: 7px;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
}

.btnbening {
  background-color: transparent !important;
  color: var(--primary-blue);
}


</style>
