<script setup>
import { onMounted } from "vue";
import { useRouter } from "vue-router";
import Grg from "../mixins/grg.js";
import Navigation from "../Navigation.vue";

const router = useRouter();
mixins: [Grg]

//lifecycle hooks
onMounted(() => {
  const inputs = document.querySelectorAll("input");
  inputs[0].focus();

  const codeBlock = document.getElementById("code-block");
  const code = document.getElementById("code");
  const form = document.querySelector("form");

  inputs.forEach((input, key) => {
    if (key !== 0) {
      input.addEventListener("click", function () {
        inputs[0].focus();
      });
    }
    input.addEventListener("keyup", function () {
      if (input.value) {
        if (key === 6) {
          // Last one tadaa
          const userCode = [...inputs].map((input) => input.value).join("");
          codeBlock.classList.remove("hidden");
          code.innerText = userCode;
        } else {
          inputs[key + 1].focus();
        }
      }
    });
  });
  Grg.methods.getPIN()
});



const redirectGuard = () => {
  router.push({ name: 'StartingPage' })
}

</script>

<template>
  <Navigation back="PilihJenisKartu" cancel="StartingPage" />
  <div class="new-pin-content">
    <div>
      <div class="new-pin-title">&nbsp;</div>
      <div class="new-pin-title">Masukkan ulang 6 digit PIN baru anda</div>
      <div>
        <form>
          <input type="password" maxlength="1" autofocus />
          <input type="password" maxlength="1" />
          <input type="password" maxlength="1" />
          <input type="password" maxlength="1" />
          <input type="password" maxlength="1" />
          <input type="password" maxlength="1" />
        </form>
      </div>
    </div>

    <!-- <div>
      <button @click="goToReEnterPIN()">Lanjut</button>
    </div> -->
  </div>
  <!-- Modal  -->
  <div id="open-modal" class="modal-window">
    <div class="modal-content">
      <div class="header-circle">
        <div class="dot">
          <img src="../../../src/assets/error-icon.png" />
        </div>
      </div>
      <div class="failed-scan-content">
        <div class="content-text">
          <div>PIN GAGAL</div>
          <div class="content-text-margin">Gagal mengoperasikan PIN</div>
        </div>
        <div>
          <a href="#" title="Close">
            <button class="button-failed-scan" @click="redirectGuard">OK</button>
          </a>
        </div>
      </div>
    </div>
   </div>
  <!-- end modal -->
</template>

<style>
.new-pin-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 75px;
  flex-grow: 1;
  margin-bottom: 84px;
  justify-content: space-between;

  font-family: "Lato", sans-serif;
  font-weight: 400;
  font-size: 25px;
  line-height: 30px;
  text-align: center;
  color: var(--primary-blue);
}

.new-pin-title {
  margin-bottom: 32px;
  font-weight: 400;
  font-size: 25px;
  line-height: 30px;
  color: var(--primary-blue);
}

.new-pin-button {
  text-align: right;
  margin-top: 28px;
  font-weight: 700;
  font-size: 26px;
  line-height: 35px;
  color: #4a4545;
}

button {
  height: 83px;
  width: 269px;
  background: var(--primary-white);
  font-weight: 700;
  font-size: 28px;
  line-height: 34px;
  color: var(--primary-blue);
  filter: drop-shadow(0px 3px 16px rgba(124, 124, 124, 0.133));
  border: none;
  border-radius: 10px;
}

form {
  display: flex;
  justify-content: center;
  align-items: center;
}
input {
  margin: 0 0.5rem;
  padding: 0.5rem;
  border: none;
  border: 1px solid #707070;
  background: var(--primary-white);
  border-radius: 10px;
  width: 50px;
  height: 50px;
  text-align: center;
  font-size: 3rem;
}
.special {
  margin-top: 2rem;
  font-size: 2rem;
  opacity: 1;
  visibility: visible;
  transition: all 0.3s ease;
}
</style>
