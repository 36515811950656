import store from '../../store/index'
import route from "../../router"
import router from '../../router';

var pinDataCache = "";
let ecatSDK = null;

export default {
    data() {
        return {
       
        }
    },
    methods: {
        tes() {
            return "nasi goreng"
        },
        OpenSDK() {
            let firstTimeOpen = true;
            if (ecatSDK === null) {
              ecatSDK = new ECatSdk().createNew();
            } else {
              firstTimeOpen = false;
            }
            ecatSDK.open(function (status) {
              console.log("SDK status: " + (status ? "opened" : "closed"));
            });
          
            if (!firstTimeOpen) {
              return;
            }
          
            let key = 1;
          
            
            var pinEventHandler = function (evt) {
              const inputs = document.querySelectorAll("input");
              const codeBlock = document.getElementById("code-block");
              const code = document.getElementById("code");
              const form = document.querySelector("form");
              //evt: {"deviceName":string, "alias":string, "eventName":string, "data":any}
              // console.log("PIN event: " + JSON.stringify(evt));
          
              if (evt.eventName === "OnGetPinCompleted") {
                console.log("Input PIN completed!");
          
                ecatSDK.deviceManager.pin.devGetPinBlock(
                  "00000000000000000000660100772688",
                  "0",
                  0x0f,
                  "ANSI",
                  "PinKey",
                  null,
                  30000,
                  function (result) {
                    if (!result.success) {
                      console.log("Failed to get PIN block!");
                      
                    } else {
                      // console.log("Succeeded to get PIN block: [" + result.data + "].");
                      // disini redux ygy

                      for (let i = 0; i < inputs.length; i++) {
                        inputs[i].focus();
                        document.activeElement.value = null;
                      }


                      if (localStorage.getItem('pin')) {
                        if (localStorage.getItem('mode') == 'BR') {
                          store.dispatch('getPin', result.data)
                          router.push({name: "PinReInput"})
                        } else if (localStorage.getItem('mode') == 'GK') {
                          store.dispatch('getPin', result.data)
                          router.push({name: "GKPinReInput"})
                        } else {
                          alert("error satu")
                        }
                        localStorage.removeItem('pin')
                      } else {
                        if (localStorage.getItem('mode') == 'BR') {
                          if (store.state.pin !== result.data) {
                            window.location.href = "#open-modal";
                          } else {
                            route.push({name: "PinSubmit"})
                          }
                        } else if (localStorage.getItem('mode') == 'GK') {
                          if (store.state.pin !== result.data) {
                            window.location.href = "#open-modal";
                          } else {
                            route.push({name: "GKPilihGantiKartu"})
                          }
                        } else if (localStorage.getItem('mode') == 'CK') {
                          route.push({name: "CKKonfirmasiKartu"})
                        } 
                        else {
                          alert("error dua")
                        }
                      }
                      



                    }
                  }
                );
          
                console.log("Input PIN has Completed")
              } else if (evt.eventName === "OnGetPinError") {
                console.log("Failed to input PIN!-->");
                key = 1
                
              } else if (evt.eventName === "OnGetDataCompleted") {
                // nomor rekening sukses
                store.dispatch('getAccountNumber', pinDataCache)
                console.log("Input data: [" + pinDataCache + "].-------->");
                // route.push({name: "CKPinInput"})
              } else if (evt.eventName === "OnGetDataError") {
                window.location.href = "#open-modal";
                console.log("Failed to input data!");
              } else if (evt.eventName === "OnKeyPressed") {
                if (key <= inputs.length) {
                  console.log("panggil")
                  document.activeElement.value = "*";
                }
                if (evt.data === "CLEAR") {
                  pinDataCache = "";
                  for (let i = 0; i < inputs.length; i++) {
                    inputs[i].focus();
                    document.activeElement.value = null;
                  }
                  key = 0
                  console.log("hajar")
                } else if (evt.data === "CANCEL") {
                  pinDataCache = "";
                } else if (evt.data === "ENTER") {
                  //ignore
                } else {
                  pinDataCache += evt.data;
                }

                // if (key <= inputs.length) {
                //   console.log("panggil")
                //   document.activeElement.value = "*";
                // }
          
                inputs[key].focus();
          
                key++;
                if (key == inputs.length) {
                  key = 0
                }
              }
            };
            ecatSDK.deviceManager.pin.devEvent.subscribe(pinEventHandler);


            var cardReaderEventHandler = function (evt) {
              console.log("Card dispenser event: " + JSON.stringify(evt));
            };
            ecatSDK.deviceManager.cardDispenser.devEvent.subscribe(
              cardReaderEventHandler
            );
          
            var cardReaderEventHandlerInsert = function (evt) {
              //evt: {"deviceName":string, "alias":string, "eventName":string, "data":any}
              console.log("Card reader event: " + JSON.stringify(evt));
              if (evt.eventName === "OnReadRawDataCompleted") {
                  // evt.data:{
                  //   m_track1:string,
                  //   m_track2:string,
                  //   m_track3:string,
                  //   m_sta:string,
                  //   m_type:number
                  //}
                  //alert("Card data: " + JSON.stringify(evt.data));
        document.getElementById("txtAreaResult").value = JSON.stringify(evt.data);
              } else if (evt.eventName === "OnReadRawDataError") {
                  console.log("Failed to read raw data!");
              } else if (evt.eventName === "OnMediaInserted") {
                  console.log("Card inserted!");
              } else if (evt.eventName === "OnMediaRemoved") {
                  console.log("Card removed!");
              }
            };
            ecatSDK.deviceManager.cardReader.devEvent.subscribe(cardReaderEventHandlerInsert);




            // function
            document.addEventListener("reset", function () {
              if (!ecatSDK.isOpened()) {
                console.log("ECAT SDK is not opened!");
                return;
              }
          
              ecatSDK.deviceManager.pin.devReset(function (result) {
                console.log(
                  (result.success ? "Succeeded" : "Failed") + " to reset PIN Pad!"
                );
              });
            });
          
            document.addEventListener("getInfo", function () {
              if (!ecatSDK.isOpened()) {
                console.log("ECAT SDK is not opened!");
                return;
              }
          
              ecatSDK.deviceManager.pin.devUpdateStatus(function (result) {
                if (!result.success) {
                  console.log("Failed to update PIN Pad status!");
                  return;
                }
          
                ecatSDK.deviceManager.pin.devGetInfo(function (result) {
                  if (!result.success) {
                    console.log("Failed to get PIN Pad info!");
                  } else {
                    console.log(
                      "Succeeded to get PIN Pad info: [" +
                        JSON.stringify(result.data) +
                        "]."
                    );
                    console.log(
                      "Device status: " +
                        GetPropertyNameByValue(
                          ValuesDef.DeviceStatus,
                          result.data.m_deviceState
                        )
                    );
                  }
                });
              });
            });
          
            document.addEventListener("getPIN", function () {
              if (!ecatSDK.isOpened()) {
                console.log("ECAT SDK is not opened!");
                return;
              }
          
              ecatSDK.deviceManager.pin.devGetPinAsyn(
                4,
                6,
                1,
                "1,2,3,4,5,6,7,8,9,0,ENTER,CANCEL,CLEAR",
                "ENTER,CANCEL",
                function (result) {
                  if (result.success) {
                    store.dispatch('getLoading', false)
                    
                    // setTimeout(() => { 
                      console.log("Succeeded to start getting PIN! bro")
                      store.dispatch('sdkpinRequest', false)
                    //  }, 5000)
                  } else {
                    console.log("Failed to start getting PIN!")
                  }
                }
              );
            });
          
            document.addEventListener("cancelGetPIN", function () {
              if (!ecatSDK.isOpened()) {
                console.log("ECAT SDK is not opened!");
                return;
              }
          
              ecatSDK.deviceManager.pin.devCancelGetPin(function (result) {
                console.log(
                  (result.success ? "Succeeded" : "Failed") +
                    " to cancel getting PIN!"
                );
              });
            });
          
            document.addEventListener("getData", function () {
              if (!ecatSDK.isOpened()) {
                console.log("ECAT SDK is not opened!");
                return;
              }
          
              ecatSDK.deviceManager.pin.devGetDataAsyn(
                16,
                1,
                "1,2,3,4,5,6,7,8,9,0,00,.,ENTER,CANCEL,CLEAR,BACKSPACE",
                "ENTER,CANCEL",
                function (result) {
                  console.log(
                    (result.success ? "Succeeded" : "Failed") +
                      " to start getting data bro!"
                  );
                }
              );
            });
          
            document.addEventListener("cancelGetData", function () {
              if (!ecatSDK.isOpened()) {
                console.log("ECAT SDK is not opened!");
                return;
              }
          
              ecatSDK.deviceManager.pin.devCancelGetData(function (result) {
                console.log(
                  (result.success ? "Succeeded" : "Failed") +
                    " to cancel getting data!"
                );
              });
            });
          
            document.addEventListener("importKey", function () {
              if (!ecatSDK.isOpened()) {
                console.log("ECAT SDK is not opened!");
                return;
              }
          
              ecatSDK.deviceManager.pin.devImportKey(
                "MasterKey",
                "0123456789ABCDEF0123456789ABCDEF",
                "CRYPT,KEYENCKEY",
                null,
                function (result) {
                  if (!result.success) {
                    console.log("Failed to import master key!");
                  } else {
                    console.log(
                      "Succeeded to import master key. KCV: [" + result.data + "]."
                    );
                  }
                }
              );
          
              ecatSDK.deviceManager.pin.devImportKey(
                "PinKey",
                "0123456789ABCDEF0123456789ABCDEF",
                "FUNCTION",
                "MasterKey",
                function (result) {
                  if (!result.success) {
                    console.log("Failed to import pin key!");
                  } else {
                    console.log(
                      "Succeeded to import pin key. KCV: [" + result.data + "]."
                    );
                  }
                }
              );
          
              ecatSDK.deviceManager.pin.devImportKey(
                "MacKey",
                "0123456789ABCDEF0123456789ABCDEF",
                "MACING",
                "MasterKey",
                function (result) {
                  if (!result.success) {
                    console.log("Failed to import mac key!");
                  } else {
                    console.log(
                      "Succeeded to import mac key. KCV: [" + result.data + "]."
                    );
                  }
                }
              );
            });
          
            document.addEventListener("encryptData", function () {
              if (!ecatSDK.isOpened()) {
                console.log("ECAT SDK is not opened!");
                return;
              }
          
              ecatSDK.deviceManager.pin.devCrypt(
                "E",
                "3DESMAC",
                "MacKey",
                0,
                null,
                null,
                "0123456789ABCDEF",
                function (result) {
                  if (!result.success) {
                    console.log("Failed to encrypt data!");
                  } else {
                    console.log(
                      "Succeeded to encrypt data. Encrypted data: [" +
                        result.data +
                        "]."
                    );
                  }
                }
              );
            });
          
            document.addEventListener("getKeyDetails", function () {
              if (!ecatSDK.isOpened()) {
                console.log("ECAT SDK is not opened!");
                return;
              }
          
              ecatSDK.deviceManager.pin.devGetKeyDetails(function (result) {
                if (!result.success) {
                  console.log("Failed to get key details!");
                } else {
                  console.log(
                    "Succeeded to get key details: \n" + JSON.stringify(result.data)
                  );
                }
              });
            });

            document.addEventListener("dispenserCard", function () {
              if (!ecatSDK.isOpened()) {
                console.log("ECAT SDK is not opened!");
                return;
              }
          
              ecatSDK.deviceManager.cardDispenser.devDispenseCard(
                ValuesDef.CardDispenserSupplyBin.First,
                ValuesDef.CardDispenserDispenseType.TRANSPORT,
                function (result) {
                  console.log((result.success ? "Succeeded bro" : "Failed") + " to dispense card!");

                  if (result.success) {
                    console.log("Succeeded to dispense card !")
                    route.push({name: "StartingPage"})
                  } else {
                    console.log("Failed to dispense card !")
                    window.location.href = "#open-modal";
                  }
                }
              );
            });

            document.addEventListener("readRawData", function () {
              if (!ecatSDK.isOpened()) {
                  console.log("ECAT SDK is not opened!");
                  return;
              }

              ecatSDK.deviceManager.cardReader.devReadRawDataAsyn(function (result) {
                  if (result.success) {
                    console.log("Succeeded to start reading raw data!")
                    // this.retainCard()
                    var ev = document.createEvent('CustomEvent');
                    ev.initCustomEvent('retainCard', false, false, '');
                    document.dispatchEvent(ev);
                  } else {
                    window.location.href = "#open-modal";
                    console.log("Failed to start reading raw data!")
                  }
              });
            });

            document.addEventListener("cancelReadRawData", function () {
              if (!ecatSDK.isOpened()) {
                  console.log("ECAT SDK is not opened!");
                  return;
              }

              ecatSDK.deviceManager.cardReader.devCancelReadRawData(function (result) {
                  console.log((result.success ? "Succeeded" : "Failed") + " to cancel reading raw data!");
              });
            });

            document.addEventListener("retainCard", function () {
              if (!ecatSDK.isOpened()) {
                  alert("ECAT SDK is not opened!");
                  return;
              }

              ecatSDK.deviceManager.cardReader.devRetainCard(function (result) {
                    if (result.success) {
                      console.log("Succeeded to start retain data!")
                      window.location.href = "#open-suksesmodal";
                    } else {
                      window.location.href = "#open-modal";
                      console.log("Failed to retain  data!")
                    }

              });
            });
        },

        reset() {
            var ev = document.createEvent('CustomEvent');
            ev.initCustomEvent('reset', false, false, '');
            document.dispatchEvent(ev);
        },

        getInfo() {
            var ev = document.createEvent('CustomEvent');
            ev.initCustomEvent('getInfo', false, false, '');
            document.dispatchEvent(ev);
        },

        getPIN() {
            var ev = document.createEvent('CustomEvent');
            ev.initCustomEvent('getPIN', false, false, '');
            document.dispatchEvent(ev);
        },

        cancelGetPIN() {
            var ev = document.createEvent('CustomEvent');
            ev.initCustomEvent('cancelGetPIN', false, false, '');
            document.dispatchEvent(ev);
        },

        getData() {
            pinDataCache = "";

            var ev = document.createEvent('CustomEvent');
            ev.initCustomEvent('getData', false, false, '');
            document.dispatchEvent(ev);
        },

        cancelGetData() {
            var ev = document.createEvent('CustomEvent');
            ev.initCustomEvent('cancelGetData', false, false, '');
            document.dispatchEvent(ev);
        },

        importKey() {
            var ev = document.createEvent('CustomEvent');
            ev.initCustomEvent('importKey', false, false, '');
            document.dispatchEvent(ev);
        },

        encryptData() {
            var ev = document.createEvent('CustomEvent');
            ev.initCustomEvent('encryptData', false, false, '');
            document.dispatchEvent(ev);
        },

        getKeyDetails() {
            var ev = document.createEvent('CustomEvent');
            ev.initCustomEvent('getKeyDetails', false, false, '');
            document.dispatchEvent(ev);
        },
   
        dispenserCard() {
            var ev = document.createEvent('CustomEvent');
            ev.initCustomEvent('dispenserCard', false, false, '');
            document.dispatchEvent(ev);
        },
   
        readRawData() {
            var ev = document.createEvent('CustomEvent');
            ev.initCustomEvent('readRawData', false, false, '');
            document.dispatchEvent(ev);
        }, 

        dispenserCard() {
          var ev = document.createEvent("CustomEvent");
          ev.initCustomEvent("dispenserCard", false, false, "");
        
          document.dispatchEvent(ev);
        },
        
        readRawData() {
          var ev = document.createEvent('CustomEvent');
          ev.initCustomEvent('readRawData', false, false, '');
          document.dispatchEvent(ev);
        },

        cancelReadRawData() {
          var ev = document.createEvent('CustomEvent');
          ev.initCustomEvent('cancelReadRawData', false, false, '');
          document.dispatchEvent(ev);
        },

        retainCard() {
          var ev = document.createEvent('CustomEvent');
          ev.initCustomEvent('retainCard', false, false, '');
          document.dispatchEvent(ev);
        }
    },
}