<script setup>
import { useRouter } from 'vue-router'
import Ektp from "../mixins/ektp.js";
import KtpJSON from "../../assets/briscan.json"
import Navigation from "../Navigation.vue";


const router = useRouter()
mixins: [Ektp]

const goToScanKtp = () => {
  console.log("click")
  Ektp.methods.homescreen()
}


</script>

<template>
  <Navigation back="StartingPage" cancel="StartingPage" />
  <div class="ktp-content">
    <div>
      <LottieAnimation :animationData="KtpJSON" :height="400" :width="400" />
      <!-- <img src="../../assets/ktp-icon.png" /> -->
    </div>
    <div style="marginTop:-150px">
      <p>1. Letakkan e-KTP anda pada KTP Reader</p>
      <p>2. Tekan Mulai</p>
      <p>3. Tunggu Sampai Proses Selesai</p>
    </div>
    <!-- <div class="textktp">
      <div>Letakkan e-KTP anda</div>
      <div>dan tekan Mulai untuk proses Pembukaan Rekening Bank</div>
    </div> -->
    <div class="request-loader">
      <button @click="goToScanKtp()">Mulai</button>
    </div>
  </div>
  <!-- Modal  -->
  <div id="open-modalektpnotfound" class="modal-window">
    <div class="modal-content">
      <div class="header-circle">
        <div class="dot">
          <img src="../../../src/assets/error-icon.png" />
        </div>
      </div>
      <div class="failed-scan-content">
        <div class="content-text">
          <div>BELUM TERHUBUNG DENGAN DEVICE E-KTP</div>
          <div class="content-text-margin">Gagal menghubungkan dengan pemindai ektp, periksa pengaturan sistem</div>
        </div>
        <div>
          <a href="#" title="Close">
            <button class="button-failed-scan" @click="router.push({ name: 'StartingPage' })">OK</button>
          </a>
        </div>
      </div>
    </div>
   </div>
  <!-- end modal -->
</template>

<style scoped>
.ktp-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 0px;
  flex-grow: 1;
  margin-bottom: 84px;
  justify-content: space-evenly;

  font-family: "Lato", sans-serif;
  font-weight: 400;
  font-size: 25px;
  line-height: 30px;
  text-align: center;
  color: var(--primary-blue);
}

.ktp-content button {
  /* padding: 21px 94px;
  cursor: pointer;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: var(--primary-white);
  background: var(--primary-blue);
  border: none;
  filter: drop-shadow(0px 3px 16px rgba(124, 124, 124, 0.133));
  border-radius: 20px; */
  height: 83px;
  width: 269px;
  background: var(--primary-blue);
  font-weight: 200;
  font-size: 28px;
  line-height: 34px;
  /* color: var(--primary-white); */
  color: var(--primary-white);
  filter: drop-shadow(0px 3px 16px rgba(124, 124, 124, 0.133));
  border: none;
  border-radius: 7px;
  z-index: 10;
}

.textktp {
  margin-top: -150px;
  margin-bottom: 20px;
}

.command {
  display: flex;
  justify-content: space-between;
  padding: 0 40px;
  font-weight: 600;
}

.command .navigation {
  width: 100px;
  height: 50px;
  font-size: 14px;
  padding: 0;
  margin-top: 10px;
}

.request-loader {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  /* height: 70px;
  width: 350px; */
  height: 83px;
  width: 269px;
  border-radius: 10px;
}

.request-loader::after {
  opacity: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  content: '';
  height: 100%;
  width: 100%;
  /* background-color: rgba(0, 0, 0, 0.2); */
  background-color: var(--primary-blue);
  border-radius: 10px;
  animation-name: ripple;
  animation-duration: 3s;
  animation-delay: 0s;
  animation-iteration-count: infinite;
  animation-timing-function: cubic-bezier(0.65, 0, 0, 0.34, 1);
  z-index: 1;
}

.request-loader::before {
  opacity: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  content: '';
  height: 100%;
  width: 100%;
  /* background-color: rgba(0, 0, 0, 0.2); */
  background-color: var(--primary-blue);

  border-radius: 7px;
  animation-name: ripple;
  animation-duration: 3s;
  animation-delay: 0.5s;
  animation-iteration-count: infinite;
  animation-timing-function: cubic-bezier(0.65, 0, 0, 0.34, 1);
  z-index: 1;
}

@keyframes ripple {
  from {
    opacity: 1;
    transform: scale3d(0.75, 0.75, 1);
  }
  to {
    opacity: 0;
    transform: scale3d(1.5, 1.5, 1);
  }
}
</style>
