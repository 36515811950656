<!-- <template>
    <div id="app">
      <input
        :value="input"
        class="input"
        @input="onInputChange"
        placeholder="Tap on the virtual keyboard to start"
      >
      <SimpleKeyboard @onChange="onChange" @onKeyPress="onKeyPress" :input="input"/>
    </div>
  </template> -->

<!-- <script>
  import SimpleKeyboard from "./SimpleKeyboard";
  
  export default {
    name: "Tes",
    components: {
      SimpleKeyboard
    },
    data: () => ({
      input: ""
    }),
    methods: {
      onChange(input) {
        this.input = input;
      },
      onKeyPress(button) {
        console.log("button", button);
      },
      onInputChange(input) {
        this.input = input.target.value;
      }
    }
  };
  </script> -->

<!-- <style scoped>
  #app {
    font-family: "Avenir", Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  input {
    width: 70vw!important;
    font-size: 14px;
    margin: 20px;
  }
  </style> -->

<script setup>
import Grg from "../mixins/grg"

mixins: [Grg]

const openSDK = () => {
  Grg.methods.OpenSDK()
}
const reset = () => {
  Grg.methods.reset()
}
const getInfo = () => {
  Grg.methods.getInfo()
}
const getPIN = () => {
  Grg.methods.getPIN()
}
const dispenserCard = () => {
  Grg.methods.dispenserCard()
}
const readRawData = () => {
  Grg.methods.readRawData()
}

const tes = () => {
  let a = Grg.methods.tes()
  console.log(a)
}


</script>

<template>
  <div>
    <button type="button" @click="tes">Tes</button>
    <button type="button" @click="openSDK">Open SDK</button>
    <button type="button" @click="reset">Reset</button>
    <button type="button" @click="getInfo">GetInfo</button>
    <button type="button" @click="getPIN">GetPIN</button>
    <button type="button" @click="dispenserCard">DispenserCard</button>
    <button type="button" @click="readRawData">ReadRawData</button>
  </div>
</template>
