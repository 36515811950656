import { createStore } from "vuex";

export default createStore({
  state: {
    ektpResults: {},
    photoResults: "",
    signatureResults: "",
    finger: "",
    pin: "",
    accountNumber:"",
    isLoading: true,
    cardRequest: [],
    sdkPinPadIsNotActive:true
  },
  mutations: {
    SET_BIODATA(state, payload) {
      state.ektpResults = payload;
    },
    SET_FINGER(state, payload) {
      state.finger = payload;
    },
    SET_PHOTO(state, payload) {
      state.photoResults = payload;
    },
    SET_SIGNATURE(state, payload) {
      state.signatureResults = payload;
    },
    SET_PIN(state, payload) {
      state.pin = payload;
    },
    SET_ACCOUNTNUMBER(state, payload) {
      state.accountNumber = payload;
    },
    SET_ISLOADING(state, payload) {
      state.isLoading = payload;
    },
    SET_CARDREQUEST(state, payload) {
      state.cardRequest = payload;
    },
    SET_PINPADSDK(state, payload) {
      state.sdkPinPadIsNotActive = payload;
    },
  },
  actions: {
    getBiodata(context, payload) {
      context.commit("SET_BIODATA", payload);
    },
    getFingerPointed(context, payload) {
      let result = null

      if (payload == "1") {
        result = "Ibu Jari Kanan Anda ";
      } else if (payload == "2") {
        result = "Jari Telunjuk Kanan Anda ";
      } else if (payload == "3") {
        result = "Jari Tengah Kanan Anda ";
      } else if (payload == "4") {
        result = "Jari Manis Kanan Anda ";
      } else if (payload == "5") {
        result = "Jari Kelingking Kanan Anda ";
      } else if (payload == "6") {
        result = "Ibu Jari Kiri Anda ";
      } else if (payload == "7") {
        result = "Jari Telunjuk Kiri Anda ";
      } else if (payload == "8") {
        result = "Jari Tengah Kiri Anda ";
      } else if (payload == "9") {
        result = "Jari Manis Kiri Anda ";
      } else if (payload == "0A") {
        result = "Jari Kelingking Kiri Anda ";
      } else {
        result = "Ooops Something Wrong";
      }

      // localStorage.setItem('finger',payload)
      context.commit("SET_FINGER", result);
    },
    getPhoto(context, payload) {
      context.commit("SET_PHOTO", payload);
    },
    getSignature(context, payload) {
      context.commit("SET_SIGNATURE", payload);
    },
    getPin(context, payload) {
      context.commit("SET_PIN", payload);
    },
    getAccountNumber(context, payload) {
      context.commit("SET_ACCOUNTNUMBER", payload);
    },
    getLoading(context, payload) {
      context.commit("SET_ISLOADING", payload);
    },
    cardRequest(context, payload) {
      context.commit("SET_CARDREQUEST", payload);
    },
    sdkpinRequest(context, payload) {
      context.commit("SET_PINPADSDK", payload);
    },
  },
  getters: {},
});
