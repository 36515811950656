<script setup>
import { onMounted } from "vue";
import { useRouter } from "vue-router";
import CardOldJSON from "../../assets/kartuLama.json";
import Grg from "../mixins/grg.js";

const router = useRouter();
mixins: [Grg]


onMounted(() => {
  Grg.methods.readRawData()
})

const redirectGuard = () => {
   router.push({ name: 'StartingPage' })
}

const nextStep = () => {
  router.push({name: "GKPinInput"})
}

const back = () => {
  Grg.methods.cancelReadRawData()
  router.push({name: 'GKAmbilFoto'})
}

const cancel = () => {
  Grg.methods.cancelReadRawData()
  router.push({name: 'StartingPage'})
}
</script>

<template>
  <div class="command">
      <button class="navigation btnbening " @click="back">Kembali</button>
      <button class="navigation btnbening " @click="cancel">Batal</button>
  </div>
  <div class="masukkan-kartu-lama-content">
    <div class="masukkan-kartu-lama-title">Masukkan Kartu Lama Anda</div>
    <!-- <div><img src="../../assets/masukkan-kartu-lama.png" /></div> -->
    <LottieAnimation :animationData="CardOldJSON" :height="300" :width="300" />
  </div>
  <!-- Modal  -->
  <div id="open-modal" class="modal-window">
    <div class="modal-content">
      <div class="header-circle">
        <div class="dot">
          <img src="../../../src/assets/error-icon.png" />
        </div>
      </div>
      <div class="failed-scan-content">
        <div class="content-text">
          <div>GAGAL MENGOPERASIKAN INSERT CARD</div>
          <div class="content-text-margin">Mohon maaf , sistem dalam kendala , harap ulangi</div>
        </div>
        <div>
          <a href="#" title="Close">
            <button class="button-failed-scan" @click="redirectGuard">OK</button>
          </a>
        </div>
      </div>
    </div>
   </div>
  <!-- end modal -->
  <!-- Modal  -->
  <div id="open-suksesmodal" class="modal-window">
    <div class="modal-content">
      <div class="header-circle">
        <div class="dot">
          <img src="../../../src/assets/checkmark.png" />
        </div>
      </div>
      <div class="failed-scan-content">
        <div class="content-text">
          <div>KARTU BERHASIL DITERIMA</div>
          <div class="content-text-margin"></div>
        </div>
        <div>
          <a href="#" title="Close">
            <button class="button-failed-scan" @click="nextStep">OK</button>
          </a>
        </div>
      </div>
    </div>
   </div>
  <!-- end modal -->
</template>

<style>
.masukkan-kartu-lama-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 75px;
    flex-grow: 1;
    margin-bottom: 84px;
    justify-content: space-around;
  
    font-family: "Lato", sans-serif;
    font-weight: 400;
    font-size: 25px;
    line-height: 30px;
    text-align: center;
    color: var(--primary-blue);
  }
  
  .masukkan-kartu-lama-title {
    font-weight: 700;
    font-size: 25px;
    line-height: 30px;
    text-align: center;
    color: var(--primary-blue);
  }
  
  button {
    height: 83px;
    width: 269px;
    background: var(--primary-white);
    font-weight: 700;
    font-size: 28px;
    line-height: 34px;
    color: var(--primary-blue);
    filter: drop-shadow(0px 3px 16px rgba(124, 124, 124, 0.133));
    border: none;
    border-radius: 10px;
  }

  .btnbening {
  background-color: transparent !important;
  color: var(--primary-blue);
}
  
</style>