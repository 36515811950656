<script setup>
import { onMounted } from "vue";
import { useRouter } from "vue-router";
import store from '../../store'
import ArrowJSON from "../../assets/scrollDown.json"
import Navigation from "../Navigation.vue";
const router = useRouter();

const ttd = () => {
  
}
//lifecycle hooks
onMounted(() => {
  // digital signature code
(function () {
  window.requestAnimFrame = (function (callback) {
    return (
      window.requestAnimationFrame ||
      window.webkitRequestAnimationFrame ||
      window.mozRequestAnimationFrame ||
      window.oRequestAnimationFrame ||
      window.msRequestAnimaitonFrame ||
      function (callback) {
        window.setTimeout(callback, 1000 / 60);
      }
    );
  })();

  var canvas = document.getElementById("sig-canvas");
  var ctx = canvas.getContext("2d");
  ctx.strokeStyle = "#222222";
  ctx.lineWidth = 4;

  var drawing = false;
  var mousePos = {
    x: 0,
    y: 0,
  };
  var lastPos = mousePos;

  canvas.addEventListener(
    "mousedown",
    function (e) {
      drawing = true;
      lastPos = getMousePos(canvas, e);
    },
    false
  );

  canvas.addEventListener(
    "mouseup",
    function (e) {
      drawing = false;
    },
    false
  );

  canvas.addEventListener(
    "mousemove",
    function (e) {
      mousePos = getMousePos(canvas, e);
    },
    false
  );

  // Add touch event support for mobile
  canvas.addEventListener("touchstart", function (e) {}, false);

  canvas.addEventListener(
    "touchmove",
    function (e) {
      var touch = e.touches[0];
      var me = new MouseEvent("mousemove", {
        clientX: touch.clientX,
        clientY: touch.clientY,
      });
      canvas.dispatchEvent(me);
    },
    false
  );

  canvas.addEventListener(
    "touchstart",
    function (e) {
      mousePos = getTouchPos(canvas, e);
      var touch = e.touches[0];
      var me = new MouseEvent("mousedown", {
        clientX: touch.clientX,
        clientY: touch.clientY,
      });
      canvas.dispatchEvent(me);
    },
    false
  );

  canvas.addEventListener(
    "touchend",
    function (e) {
      var me = new MouseEvent("mouseup", {});
      canvas.dispatchEvent(me);
    },
    false
  );

  function getMousePos(canvasDom, mouseEvent) {
    var rect = canvasDom.getBoundingClientRect();
    return {
      x: mouseEvent.clientX - rect.left,
      y: mouseEvent.clientY - rect.top,
    };
  }

  function getTouchPos(canvasDom, touchEvent) {
    var rect = canvasDom.getBoundingClientRect();
    return {
      x: touchEvent.touches[0].clientX - rect.left,
      y: touchEvent.touches[0].clientY - rect.top,
    };
  }

  function renderCanvas() {
    if (drawing) {
      ctx.moveTo(lastPos.x, lastPos.y);
      ctx.lineTo(mousePos.x, mousePos.y);
      ctx.stroke();
      lastPos = mousePos;
    }
  }

  // Prevent scrolling when touching the canvas
  document.body.addEventListener(
    "touchstart",
    function (e) {
      if (e.target == canvas) {
        e.preventDefault();
      }
    },
    false
  );
  document.body.addEventListener(
    "touchend",
    function (e) {
      if (e.target == canvas) {
        e.preventDefault();
      }
    },
    false
  );
  document.body.addEventListener(
    "touchmove",
    function (e) {
      if (e.target == canvas) {
        e.preventDefault();
      }
    },
    false
  );

  (function drawLoop() {
    requestAnimFrame(drawLoop);
    renderCanvas();
  })();

  function clearCanvas() {
    canvas.width = canvas.width;
  }

  // Set up the UI
  var sigText = document.getElementById("sig-dataUrl");
  var sigImage = document.getElementById("sig-image");
  var clearBtn = document.getElementById("sig-clearBtn");
  var submitBtn = document.getElementById("sig-submitBtn");

  // Misalnya ada clear button actionnya pake yang ini
  clearBtn.addEventListener("click", function(e) {
    clearCanvas();
    sigText.innerHTML = "Data URL for your signature will go here!";
    sigImage.setAttribute("src", "");
  }, false);

  submitBtn.addEventListener(
    "click",
    function (e) {
      var dataUrl = canvas.toDataURL();
      console.log(dataUrl.length)
      if (dataUrl.length == 7326) {
        window.location.href = "#open-modal";
      } else {
        store.dispatch('getSignature', dataUrl)
        router.push({ name: "PilihKartu" })
      }

      // setTimeout(() => { router.push({ name: "PilihKartu" }); }, 100)
      // sigText.innerHTML = dataUrl;
      // sigImage.setAttribute("src", dataUrl);
    },
    false
  );

})();
});



// const redirectGuard = () => {
//   window.location.href = "#";
// }

// const redirectGuardAgree = () => {
//   window.location.href = "#";
//   // disini untuk merubah is agree
// }

const back = () => {
  router.push({name:'BiodataEktp'})
}

const cancel = () => {
  router.push({name:'StartingPage'})
}

</script>

<script>
  export default {
    name: "Signature",
    data() {
      return {
        isAgree:[]
      }
    },
    methods: {
      term() {
        if (this.isAgree.length == 0) {
          window.location.href = "#open-term";
        } else {
          this.isAgree = []
        }
      },
      redirectGuard() {
        this.isAgree = []
        window.location.href = "#";
      },
      redirectGuardNew() {
        window.location.href = "#";
      },
      redirectGuardAgree() {
        this.isAgree.push("setuju")
        window.location.href = "#";
      },
    }
  }
</script>

<template>
  <div style="z-index: 50;">
    <div class="command">
      <button class="navigation btnbening" @click="back">Kembali</button>
      <button class="navigation btnbening" @click="cancel">Batal</button>
  </div>
  </div>
  <div class="guideStylus">
    <div class="wrapLottie">
      <LottieAnimation :animationData="ArrowJSON" :height="50" :width="50" />
    </div>
    <div class="wrapLottie">
      <LottieAnimation :animationData="ArrowJSON" :height="50" :width="50" />
    </div>
    <div class="wrapLottie">
      <LottieAnimation :animationData="ArrowJSON" :height="50" :width="50" />
    </div>
    <div>
    </div>
    <div>
      <p style="fontSize:20px">Gunakan <br> Stylus Pen <br> disamping ini <br> untuk menulis <br> tanda tangan</p>
    </div>
  </div>

  <div class="darkfocus">
    <div class="signature-content">
      <div :style="{visibility: isAgree.length == 0 ? 'hidden' : 'visible'}">
        <!-- <div class="signature-title">Tanda Tangan Digital</div> -->
        
        <div class="signature-description">
          Silahkan tanda tangan di kotak putih dibawah ini
        </div>
        <!-- <LottieAnimation :animationData="ArrowJSON" :height="50" :width="50" /> -->
        <div>
          <canvas id="sig-canvas" width="622" height="412"> </canvas>
          <!-- <button id="sig-clearBtn">Clear</button> -->
        </div>
        <!-- <div><img src="../../assets/arrow-icon.png" /></div> -->
        
      </div>
        <div style="display:flex;margin-top:10px">
          <input type="checkbox" id="check" value="setuju" v-model="isAgree" name="isAgree" @click="term">
          <div style="margin-top:15px;margin-left:20px;color:white">
            <label for="check" v-if="isAgree.length == 0">Klik untuk menuju persetujuan pemohon</label>
            <label for="check" v-if="isAgree.length > 0">Pemohon menyetujui persyaratan yang berlaku</label>
          </div>
        </div>
      <div class="buttonWrapSign request-loader">
        <button  :disabled="isAgree.length === 0" id="sig-clearBtn">Ulangi</button>
        <button  :disabled="isAgree.length === 0" id="sig-submitBtn">Lanjutkan</button>
        <!-- <button class="btnsignback" @click="back">Kembali</button> -->
      </div>
    </div>

  </div>
  <!-- Modal  -->
  <div id="open-modal" class="modal-window">
    <div class="modal-content">
      <div class="header-circle">
        <div class="dot">
          <img src="../../../src/assets/error-icon.png" />
        </div>
      </div>
      <div class="failed-scan-content">
        <div class="content-text">
          <div>TANDA TANGAN BELUM DIBUAT</div>
          <div class="content-text-margin">Anda harus memasukan tanda tangan dalam pembukaan rekening baru</div>
        </div>
        <div>
          <a href="#" title="Close">
            <button class="button-failed-scan" @click="redirectGuardNew">OK</button>
          </a>
        </div>
      </div>
    </div>
   </div>
  <!-- end modal -->

  <!-- Modal  -->
  <div id="open-term" class="modal-window" style="margin:0;padding:0;">
    <div class="modal-content" style="min-width:80vw;min-height:850px">
      
      <div class="content-text" >
        <div>PERJANJIAN DAN PERSYARATAN</div>
        <div class="content-text-margin" style="overflow-y:scroll;text-align:justify;padding:40px;position:relative">
          <div class="ptsentuh">
            <img src="../../assets/logo_sentuhNew.png" alt="" style="width:100%">
          </div>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Vel repellat, sapiente ullam aspernatur corrupti, dolorem vero tenetur doloribus ipsam, voluptas quidem rerum nulla quod! Nam deserunt magnam animi aliquam qui?
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Vel repellat, sapiente ullam aspernatur corrupti, dolorem vero tenetur doloribus ipsa
          <ol>
            <li>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Numquam maxime tempore necessitatibus ipsam, iste repudiandae aperiam repellendus aliquam, delectus</li>
            <li>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Numquam maxime tempore necessitatibus ipsam, iste repudiandae aperiam repellendus aliquam, delectus</li>
            <li>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Numquam maxime tempore necessitatibus ipsam, iste repudiandae aperiam repellendus aliquam, delectus</li>
          </ol>
          <br><br>
          Lorem, ipsum dolor sit amet consectetur adipisicing elit. Ullam, laboriosam quidem. Culpa asperiores consequatur unde eius laboriosam repellendus quia deleniti. Repudiandae velit nisi a, quae temporibus officia asperiores eaque ea.
          Lorem, ipsum dolor sit amet consectetur adipisicing elit. Ullam, laboriosam quidem. Culpa asperiores consequatur unde eius laboriosam repellendus quia deleniti. Repudiandae velit nisi a, quae temporibus officia asperiores eaque ea.
          Lorem, ipsum dolor sit amet consectetur adipisicing elit. Ullam, laboriosam quidem. Culpa asperiores consequatur unde eius laboriosam repellendus quia deleniti. Repudiandae velit nisi a, quae temporibus officia asperiores eaque ea.
          Lorem, ipsum dolor sit amet consectetur adipisicing elit. Ullam, laboriosam quidem. Culpa asperiores consequatur unde eius laboriosam repellendus quia deleniti. Repudiandae velit nisi a, quae temporibus officia asperiores eaque ea.
          Lorem, ipsum dolor sit amet consectetur adipisicing elit. Ullam, laboriosam quidem. Culpa asperiores consequatur unde eius laboriosam repellendus quia deleniti. Repudiandae velit nisi a, quae temporibus officia asperiores eaque ea.
          Lorem, ipsum dolor sit amet consectetur adipisicing elit. Ullam, laboriosam quidem. Culpa asperiores consequatur unde eius laboriosam repellendus quia deleniti. Repudiandae velit nisi a, quae temporibus officia asperiores eaque ea.
          Lorem, ipsum dolor sit amet consectetur adipisicing elit. Ullam, laboriosam quidem. Culpa asperiores consequatur unde eius laboriosam repellendus quia deleniti. Repudiandae velit nisi a, quae temporibus officia asperiores eaque ea.
          Lorem, ipsum dolor sit amet consectetur adipisicing elit. Ullam, laboriosam quidem. Culpa asperiores consequatur unde eius laboriosam repellendus quia deleniti. Repudiandae velit nisi a, quae temporibus officia asperiores eaque ea.
        </div>
      </div>
      <div class="failed-scan-content"  >
        <div style="display:flex;flex-direction:column;padding:20px">
          <a href="#" title="Close">
            <button style="margin-bottom:10px;background:gray"  @click="redirectGuard">BATALKAN</button>
          </a>
          <a href="#" title="Close">
            <button  @click="redirectGuardAgree">SAYA SETUJU</button>
          </a>
        </div>
      </div>
    </div>
   </div>
  <!-- end modal -->
</template>

<style scoped>
.signature-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
  flex-grow: 1;
  margin-bottom: 84px;
  justify-content: space-between;

  font-family: "Lato", sans-serif;
  font-weight: 400;
  font-size: 25px;
  line-height: 30px;
  text-align: center;
  color: var(--primary-white);
}

.signature-title {
  margin-bottom: 32px;
  font-weight: 200;
  font-size: 25px;
  line-height: 30px;
  text-align: center;
  color: var(--primary-white);
}

.signature-button {
  text-align: right;
  margin-top: 28px;
  font-weight: 200;
  font-size: 26px;
  line-height: 35px;
  color: #4a4545;
}

button {
  height: 83px;
  width: 269px;
  font-weight: 200;
  font-size: 28px;
  line-height: 34px;
  background: var(--primary-bri-secondary);
  color: var(--primary-white);
  filter: drop-shadow(0px 3px 16px rgba(124, 124, 124, 0.133));
  border: none;
  border-radius: 7px;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
}

.signature-description {
  font-weight: 200;
  font-size: 25px;
  line-height: 30px;
  margin-bottom: 30px;
  color: #fff;
}

#sig-canvas {
  border-radius: 7px;
  cursor: crosshair;
  border: 5px solid var(--primary-bri-secondary);
  width: 622px;
  height: 412px;
  background-color: #fff;
}

#sig-clearBtn {
  /* font-size: 12px;
  padding: 0;
  margin: 0;
  position: absolute;
  right: 0px;
  top: -20px;
  background-color: var(--primary-bri-secondary);
  color: #000000; */
  background-color: var(--primary-white);
  color: var(--primary-bri-secondary);
  margin-right: 20px;
}

.darkfocus {
  position: absolute;
  width: 100%;
  top: 144.9px;
  height: 84.9vh;
  background: rgba(0, 0, 0, 0.6);
  opacity: 0.8;
  background: var(--primary-blue);
  z-index: 10;
}

.btnsignback {
  margin-left: 10px;
  background-color: var(--primary-white);
  color: var(--primary-blue);
}

.buttonWrapSign {
  margin-top: 100px;
}

.guideStylus {
  position: absolute;
  right: 0;
  top: 200px;
  z-index: 100;
  display: flex;
}

.guideStylus .wrapLottie {
    -moz-transform: rotate(-90deg);
    -webkit-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
}

.guideStylus p {
  color: gold;
}

.btnbening {
  background-color: transparent!important;
}

.command .navigation {
  width: 100px;
  height: 50px;
  font-size: 14px;
  padding: 0;
  margin-top: 10px;
}

/* ===== Scrollbar CSS ===== */
  /* Firefox */
  * {
    scrollbar-width: auto;
    scrollbar-color: var(--primary-bri-secondary) #ffffff;
  }

  /* Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 16px;
  }

  *::-webkit-scrollbar-track {
    background: #ffffff;
  }

  *::-webkit-scrollbar-thumb {
    background-color: var(--primary-bri-secondary);
    border-radius: 10px;
    border: 3px solid #ffffff;
  }

  .ptsentuh {
    position: fixed;
    right: 0;
    top: 50px;
    padding: 40px;
    opacity: 0.1;
  }
</style>
