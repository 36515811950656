<!-- <script setup>
import { onMounted } from "vue";
import { useRouter } from "vue-router";
import Navigation from "../Navigation.vue";
const router = useRouter();

</script> -->

<!-- <template>
  <Navigation back="TandaTangan" cancel="StartingPage" />
  <div class="select-card-content">
    <div>
      <div class="select-card-title">Silahkan Pilih Kartu</div>
      <router-link to="/pilihJenisKartu"><img src="../../assets/select-card.png" /></router-link>
    </div>
    <div class="btn-wrapper">
      <div>
        <button class="buttoncircle" disabled>
          <div class="btn-content">&#x3c;</div>
        </button>
      </div>
      <div>
        <button class="buttoncircle">
          <div class="btn-content">&#x3e;</div>
        </button>
      </div>
    </div>
  </div>
</template> -->

<script setup>
import { useRouter } from "vue-router";
import store from "../../store"
const router = useRouter();

const chose = (data) => {
  store.dispatch('cardRequest', data)
  setTimeout(() => { 
    router.push({ name: "PilihJenisKartu" });
   }, 100)
};
</script>

<script>
// If you are using PurgeCSS, make sure to whitelist the carousel CSS classes
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide, Pagination, Navigation } from "vue3-carousel";
import Navigations from "../Navigation.vue";

export default {
  name: "App",
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
    Navigation,
  },
  mounted() {
    setTimeout(() => {
      window.location.href = "#open-modal";
    }, 1000);
  },
  data() {
    return {
      cards: [
        {
          id: 1,
          name: "Gold Card",
          path: "oldgold.png",
          desc: "Nominal pengambilan tunai di atm sebesar Rp. 5.000.000 /hari, untuk transfer ke Bank lain sebesar Rp. 50.000.000 / transaksi",
        },
        {
          id: 2,
          name: "Silver Card",
          path: "oldsilver.png",
          desc: "Nominal pengambilan tunai di atm sebesar Rp. 1.200.000 /hari, untuk transfer ke Bank lain sebesar Rp. 10.000.000 / transaksi",
        },
        {
          id: 3,
          name: "Platinum Card",
          path: "oldblack.png",
          desc: "Nominal pengambilan tunai di atm sebesar Rp. 100.000.000 /hari, untuk transfer ke Bank lain sebesar Rp. 1.000.000.000 / transaksi",
        },
      ],
    };
  },
};
</script>

<template>
  <div>
    <Navigations back="TandaTangan" cancel="StartingPage" />
    <div style="width: 1000px; margin: 0 auto; margin-top: 50px">
      <div class="select-card-title">Silahkan Pilih Kartu</div>
      <carousel :items-to-show="1">
        <slide v-for="slide in cards" :key="slide.id">
          <!-- {{ slide }} -->
          <div class="wrapCards">
            <img
              :src="require(`@/assets/card/${slide.path}`)"
              :alt="slide.name"
              style="width: 50%;margin: auto;"
              @click="chose(slide)"
            />
            <h2>{{ slide.name }}</h2>
            <p>{{ slide.desc }}</p>
          </div>
        </slide>
        <template #addons>
          <navigation />
          <!-- <pagination /> -->
        </template>
      </carousel>
    </div>
  </div>
  <!-- MODAL -->
  <div id="open-modal" class="modal-window">
    <div class="modal-content">
      <div class="failed-scan-content">
        <div class="content-text">
          <div>SENTUH KARTU UNTUK MEMILIH</div>
          <div class="content-text-margin">
            Pilih Kartu yang anda inginkan dengan menekan gambar kartu , anda
            juga dapat memilih kartu yang lainnya dengan menggeser layar atau
            tekan tombol next atau previous
          </div>
        </div>
        <div>
          <a href="#" title="Close">
            <button>MENGERTI</button>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.wrapCards {
  display: flex;
  flex-direction: column;
  padding: 100px;
}

.select-card-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 75px;
  flex-grow: 1;
  margin-bottom: 84px;
  justify-content: space-between;

  font-family: "Lato", sans-serif;
  font-weight: 400;
  font-size: 25px;
  line-height: 30px;
  text-align: center;
  color: var(--primary-blue);
}

.select-card-title {
  margin-bottom: 32px;
  font-weight: 700;
  font-size: 25px;
  line-height: 30px;
  text-align: center;
  color: var(--primary-blue);
}

.btn-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 60%;
}

.btn-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.buttoncircle {
  height: 72px;
  width: 72px;
  background: var(--primary-blue);
  border: 1px solid #e5e5e5;
  border-radius: 50%;
  color: var(--primary-white);
  font-size: 30px;
}

button:disabled {
  background: #d1d1d1;
}

.carousel__next {
  background-color: var(--primary-blue);
  color: #e5e5e5;
  padding: 20px;
  border-radius: 50%;
}

.carousel__next:hover {
  color: var(--primary-bri-secondary);
}

.carousel__prev:hover {
  color: var(--primary-bri-secondary);
}
.carousel__prev {
  background-color: var(--primary-blue);
  color: #e5e5e5;
  padding: 20px;
  border-radius: 50%;
}
</style>
