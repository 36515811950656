<script setup>
import { onMounted } from "vue";
import { useRouter } from "vue-router";
const router = useRouter();

//lifecycle hooks
onMounted(() => {
  console.log(`The initial count`);
});

const getBiodata = () => {
  router.push({ name: "BiodataEktp"});
};
</script>

<template>
  <div class="ambil-foto-diri-success-content">
    <div class="ambil-foto-diri-success-title">Berhasil!</div>
    <div class="ambil-foto-diri-success-image">
      <img src="../../assets/ambil-foto-diri-pre.png" />
      <img src="../../assets/checkmark.png" class="ambil-foto-diri-success-icon" />
    </div>
    <div>Foto Anda sudah terverfikasi</div>
    <div>
      <button @click="getBiodata()">Ok</button>
    </div>
  </div>
</template>

<style>
.ambil-foto-diri-success-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 75px;
  flex-grow: 1;
  margin-bottom: 84px;
  justify-content: space-around;

  font-family: "Lato", sans-serif;
  font-weight: 400;
  font-size: 25px;
  line-height: 30px;
  text-align: center;
  color: var(--primary-blue);
}

.ambil-foto-diri-success-title {
  font-weight: 700;
  font-size: 25px;
  line-height: 30px;
  text-align: center;
  color: var(--primary-blue);
}

.ambil-foto-diri-success-image {
  position: relative;
  display: flex;
}

.ambil-foto-diri-success-icon {
  z-index: 1;
  position: absolute;
  left: 40%;
  bottom: 40%;
}

button {
  height: 83px;
  width: 269px;
  background: var(--primary-white);
  font-weight: 700;
  font-size: 28px;
  line-height: 34px;
  color: var(--primary-blue);
  filter: drop-shadow(0px 3px 16px rgba(124, 124, 124, 0.133));
  border: none;
  border-radius: 10px;
}
</style>
