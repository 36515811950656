<script setup> 
import { useRouter } from 'vue-router';
const router = useRouter()
</script>

<script>
    export default {
        props: {
            back: {
                type: String,
                default: ''
            },
            cancel: {
                type: String,
                default: ''
            },
        }
    }
</script>

<template>
    <div class="command">
        <button class="navigation" @click="router.push({name: back})">Kembali</button>
        <button class="navigation red" @click="router.push({name: cancel})">Batal</button>
    </div>
</template>

<style>
.command {
    display: flex;
    justify-content: space-between;
    padding: 0 40px;
    font-weight: 700;
}

.navigation {
    max-width: 100px;
    max-height: 50px;
    font-size: 14px!important;
    padding: 0;
    margin-top: 10px;
    background: none!important;
    color: var(--primary-blue);
    font-weight: 300;
}

.red {
    color: red;
}
</style>