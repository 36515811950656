<script setup>
import { useRouter } from "vue-router";
import Ektp from "../mixins/ektp.js";
import FingerJSON from "../../assets/brifinger.json"
import Navigation from "../Navigation.vue";


const router = useRouter();
mixins: [Ektp]




const goToScanFinger = () => {
  Ektp.methods.matchJari()
  router.push({ name: "JanganPindahkanJari" });
};
</script>

<template>
  <Navigation back="LetakanKtp" cancel="StartingPage" />
  <div class="scan-finger-content">
    <!-- <div>Mohon Tunggu Sedang Proses …</div> -->
    <!-- <div><img src="../../assets/scan-finger.png" /></div> -->
    <LottieAnimation :animationData="FingerJSON" :height="150" :width="150" />
    
    <div>
      <p>1. Letakkan <span style="color:var(--primary-bri-secondary);font-weight: 700;">{{$store.state.finger}}</span> Pada Mesin Pembaca jari</p>
      <p>2. Tekan Mulai</p>
      <p>3. Tunggu Sampai Proses Selesai</p>
    </div>
    <div>
      <button @click="goToScanFinger()">Mulai</button>
    </div>
  </div>
</template>

<style scoped>
.scan-finger-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 75px;
  flex-grow: 1;
  margin-bottom: 84px;
  justify-content: space-around;

  font-family: "Lato", sans-serif;
  font-weight: 400;
  font-size: 25px;
  line-height: 30px;
  text-align: center;
  color: var(--primary-blue);
}

.scan-finger-content button {
  height: 83px;
  width: 269px;
  background: var(--primary-blue);
  font-weight: 200;
  font-size: 28px;
  line-height: 34px;
  color: var(--primary-white);
  filter: drop-shadow(0px 3px 16px rgba(124, 124, 124, 0.133));
  border: none;
  border-radius: 7px;
}
</style>
