<script setup>
import { useRouter } from "vue-router";
import Navigation from "../Navigation.vue";
const router = useRouter();




const goToCreatePIN = () => {
  router.push({ name: "PinInput" });
};
</script>

<template>
  <Navigation back="PilihKartu" cancel="StartingPage" />
  <div class="create-card-content">
    <div>
      <div class="create-card-title">Silahkan Pilih Kartu</div>
      <div>
        <img :src="require(`@/assets/card/${$store.state.cardRequest.path}`)" class="card-img" />
      </div>
      <div class="create-card-option">
        <div class="option-item">
          <input type="radio" id="test1" name="radio-group" checked />
          <label for="test1">Kartu {{$store.state.cardRequest.name}} Dengan Nama</label>
          <div class="option-description">
            Pembuatan kartu dengan nama akan memakan waktu 7 Hari kerja.
          </div>
        </div>
        <div class="option-item">
          <input type="radio" id="test2" name="radio-group" />
          <label for="test2">Kartu {{$store.state.cardRequest.name}} Instan (Tanpa Nama)</label>
          <div class="option-description">
            Pembuatan kartu instan atau tanpa nama akan langsung dicetak tanpa
            harus ke CS
          </div>
        </div>
      </div>
    </div>
    <div class="request-loader">
      <button @click="goToCreatePIN()">Konfirmasi</button>
    </div>
  </div>
</template>

<style scoped>
.create-card-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 15px;
  flex-grow: 1;
  margin-bottom: 84px;
  justify-content: space-around;

  font-family: "Lato", sans-serif;
  font-weight: 400;
  font-size: 25px;
  line-height: 30px;
  text-align: center;
  color: var(--primary-blue);
}

.create-card-title {
  margin-bottom: 32px;
  font-weight: 700;
  font-size: 25px;
  line-height: 30px;
  text-align: center;
  color: var(--primary-blue);
}

.card-img {
  width: 350px;
  height: 207px;
  margin-bottom: 22px;
}

.create-card-option {
  text-align: left;
  margin: 0 auto;
}

.option-item {
  margin-bottom: 33px;
}

.option-description {
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: #343434;
}

button {
  height: 83px;
  width: 269px;
  font-weight: 200;
  font-size: 28px;
  line-height: 34px;
  background: var(--primary-blue);
  color: var(--primary-white);
  filter: drop-shadow(0px 3px 16px rgba(124, 124, 124, 0.133));
  border: none;
  border-radius: 7px;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
}

[type="radio"]:checked,
[type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}
[type="radio"]:checked + label,
[type="radio"]:not(:checked) + label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  display: inline-block;
  font-weight: 400;
  font-size: 26px;
  line-height: 20px;
  color: #4155aa;
  margin-bottom: 13px;
}
[type="radio"]:checked + label:before,
[type="radio"]:not(:checked) + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 18px;
  height: 18px;
  border: 1px solid #ddd;
  border-radius: 100%;
  background: transparent;
}
[type="radio"]:checked + label:after,
[type="radio"]:not(:checked) + label:after {
  content: "";
  width: 12px;
  height: 12px;
  background: var(--primary-blue);
  position: absolute;
  top: 4px;
  left: 4px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
[type="radio"]:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}
[type="radio"]:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}
</style>
