import { createWebHistory, createRouter } from "vue-router";
import StartingPage from "../components/StartingPage.vue";
// Buka Rekening
import LetakanKTP from "../components/BukaRekening/LetakanKTP.vue";
import JanganPindahkanKTP from "../components/BukaRekening/JanganPindahkanKTP.vue";
import LetakanJari from "../components/BukaRekening/LetakanJari.vue";
import JanganPindahkanJari from "../components/BukaRekening/JanganPindahkanJari.vue";
import AmbilFoto from "../components/BukaRekening/AmbilFoto.vue";
import AmbilFotoBerhasil from "../components/BukaRekening/AmbilFotoBerhasil.vue";
import BiodataEktp from "../components/BukaRekening/BiodataEktp.vue";
import TandaTangan from "../components/BukaRekening/TandaTangan.vue";
import PilihKartu from "../components/BukaRekening/PilihKartu.vue";
import PilihJenisKartu from "../components/BukaRekening/PilihJenisKartu.vue";
import PinInput from "../components/BukaRekening/PinInput.vue";
import PinReInput from "../components/BukaRekening/PinReInput.vue";
import PinSubmit from "../components/BukaRekening/PinSubmit.vue";
import KonfirmasiKartu from "../components/BukaRekening/KonfirmasiKartu.vue";
import CetakKartu from "../components/BukaRekening/CetakKartu.vue";
import Tes from "../components/BukaRekening/Tes.vue"
// Ganti Kartu
import GKLetakanKTP from "../components/GantiKartu/LetakanKTP.vue";
import GKJanganPindahkanKTP from "../components/GantiKartu/JanganPindahkanKTP.vue";
import GKLetakanJari from "../components/GantiKartu/LetakanJari.vue";
import GKJanganPindahkanJari from "../components/GantiKartu/JanganPindahkanJari.vue";
import GKAmbilFoto from "../components/GantiKartu/AmbilFoto.vue";
import GKMasukanKartuLama from "../components/GantiKartu/MasukanKartuLama.vue";
import GKPinInput from "../components/GantiKartu/PinInput.vue";
import GKPinReInput from "../components/GantiKartu/PinReInput";
import GKPilihGantiKartu from "../components/GantiKartu/PilihGantiKartu.vue";
import GKPilihKartu from "../components/GantiKartu/PilihKartu.vue";
import GKCetakKartu from "../components/GantiKartu/CetakKartu.vue";
import GKSelectChangeCard from "../components/GantiKartu/SelectChangeCard.vue";
// Cetak Kartu
import CKLetakanKTP from "../components/CetakKartu/LetakanKTP.vue";
import CKJanganPindahkanKTP from "../components/CetakKartu/JanganPindahkanKTP.vue";
import CKLetakanJari from "../components/CetakKartu/LetakanJari.vue";
import CKJanganPindahkanJari from "../components/CetakKartu/JanganPindahkanJari.vue";
import CKAmbilFoto from "../components/CetakKartu/AmbilFoto.vue";
import CKNorek from "../components/CetakKartu/AmbilFoto.vue";
import CKPinInput from "../components/CetakKartu/PinInput.vue";
import CKPinReInput from "../components/CetakKartu/PinReInput.vue";
import CKKonfirmasiKartu from "../components/CetakKartu/KonfirmasiKartu.vue";
import CKCetakKartu from "../components/CetakKartu/CetakKartu.vue";
import CKPilihKartu from "../components/CetakKartu/PilihKartu.vue";
import CKInputNomorRekening from "../components/CetakKartu/InputNomorRekening.vue";







const routes = [
  {
    path: "/",
    name: "StartingPage",
    component: StartingPage,
  },
  {
    path: "/letakanKtp",
    name: "LetakanKtp",
    component: LetakanKTP,
  },
  {
    path: "/janganPindahkanKtp",
    name: "JanganPindahkanKtp",
    component: JanganPindahkanKTP,
  },
  {
    path: "/letakanJari",
    name: "LetakanJari",
    component: LetakanJari,
  },
  {
    path: "/janganPindahkanJari",
    name: "JanganPindahkanJari",
    component: JanganPindahkanJari,
  },
  {
    path: "/ambilFoto",
    name: "AmbilFoto",
    component: AmbilFoto,
  },
  {
    path: "/ambilFotoBerhasil",
    name: "AmbilFotoBerhasil",
    component: AmbilFotoBerhasil,
  },
  {
    path: "/biodataKtp",
    name: "BiodataEktp",
    component: BiodataEktp,
  },
  {
    path: "/tandaTangan",
    name: "TandaTangan",
    component: TandaTangan,
  },
  {
    path: "/pilihKartu",
    name: "PilihKartu",
    component: PilihKartu,
  },
  {
    path: "/pilihJenisKartu",
    name: "PilihJenisKartu",
    component: PilihJenisKartu,
  },
  {
    path: "/pinInput",
    name: "PinInput",
    component: PinInput,
  },
  {
    path: "/pinReInput",
    name: "PinReInput",
    component: PinReInput,
  },
  {
    path: "/pinSubmit",
    name: "PinSubmit",
    component: PinSubmit,
  },
  {
    path: "/konfirmasiKartu",
    name: "KonfirmasiKartu",
    component: KonfirmasiKartu,
  },
  {
    path: "/cetakKartu",
    name: "CetakKartu",
    component: CetakKartu,
  },
  {
    path: "/tes",
    name: "Tes",
    component: Tes,
  },
  // Cetak Kartu
  {
    path: "/ckletakanKtp",
    name: "CKLetakanKtp",
    component: CKLetakanKTP,
  },
  {
    path: "/ckjanganPindahkanKtp",
    name: "CKJanganPindahkanKtp",
    component: CKJanganPindahkanKTP,
  },
  {
    path: "/ckletakanJari",
    name: "CKLetakanJari",
    component: CKLetakanJari,
  },
  {
    path: "/ckjanganPindahkanJari",
    name: "CKJanganPindahkanJari",
    component: CKJanganPindahkanJari,
  },
  {
    path: "/ckambilFoto",
    name: "CKAmbilFoto",
    component: CKAmbilFoto,
  },
  {
    path: "/cknoRek",
    name: "CKNoRek",
    component: CKNorek,
  },
  {
    path: "/ckpinInput",
    name: "CKPinInput",
    component: CKPinInput,
  },
  {
    path: "/ckPinReInput",
    name: "CKPinReInput",
    component: CKPinReInput,
  },
  {
    path: "/ckkonfirmasiKartu",
    name: "CKKonfirmasiKartu",
    component: CKKonfirmasiKartu,
  },
  {
    path: "/ckcetakKartu",
    name: "CKCetakKartu",
    component: CKCetakKartu,
  },
  {
    path: "/ckinputnomerrekening",
    name: "CKInputNomorRekening",
    component: CKInputNomorRekening,
  },
  // Ganti Kartu
  {
    path: "/gkletakanKtp",
    name: "GKLetakanKtp",
    component: GKLetakanKTP,
  },
  {
    path: "/gkjanganPindahkanKtp",
    name: "GKJanganPindahkanKtp",
    component: GKJanganPindahkanKTP,
  },
  {
    path: "/gkletakanJari",
    name: "GKLetakanJari",
    component: GKLetakanJari,
  },
  {
    path: "/gkjanganPindahkanJari",
    name: "GKJanganPindahkanJari",
    component: GKJanganPindahkanJari,
  },
  {
    path: "/gkambilFoto",
    name: "GKAmbilFoto",
    component: GKAmbilFoto,
  },
  {
    path: "/gkmasukanKartuLama",
    name: "GKMasukanKartuLama",
    component: GKMasukanKartuLama,
  },
  {
    path: "/gkpinInput",
    name: "GKPinInput",
    component: GKPinInput,
  },
  {
    path: "/gkpinReInput",
    name: "GKPinReInput",
    component: GKPinReInput,
  },
  {
    path: "/gkpilihGantiKartu",
    name: "GKPilihGantiKartu",
    component: GKPilihGantiKartu,
  },
  {
    path: "/gkcetakKartu",
    name: "GKCetakKartu",
    component: GKCetakKartu,
  },
  {
    path: "/gkselectChangeCard",
    name: "GKSelectChangeCard",
    component: GKSelectChangeCard,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router