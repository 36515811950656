<script setup>
import { onMounted,onBeforeMount } from "vue";
import { useRouter } from "vue-router";
import Grg from "../mixins/grg.js";
import Navigation from "../Navigation.vue";

const router = useRouter();
mixins: [Grg]

onBeforeMount(() => {
  Grg.methods.getPIN()
})


//lifecycle hooks
onMounted(() => {
  const inputs = document.querySelectorAll("input");
  inputs[0].focus();

  const codeBlock = document.getElementById("code-block");
  const code = document.getElementById("code");
  const form = document.querySelector("form");

  inputs.forEach((input, key) => {
    if (key !== 0) {
      input.addEventListener("click", function () {
        inputs[0].focus();
      });
    }
    input.addEventListener("keyup", function () {
      if (input.value) {
        if (key === 6) {
          // Last one tadaa
          const userCode = [...inputs].map((input) => input.value).join("");
          codeBlock.classList.remove("hidden");
          code.innerText = userCode;
        } else {
          inputs[key + 1].focus();
        }
      }
    });
  });
  console.log("pin load done")
});

const back = () => {
  const inputs = document.querySelectorAll("input");
  for (let i = 0; i < inputs.length; i++) {
    inputs[i].focus();
    document.activeElement.value = null;
  }
  Grg.methods.cancelGetPIN()
  localStorage.removeItem('pin')
  router.push({name: 'PinInput'})
}

const cancel = () => {
  const inputs = document.querySelectorAll("input");
  for (let i = 0; i < inputs.length; i++) {
    inputs[i].focus();
    document.activeElement.value = null;
  }
  Grg.methods.cancelGetPIN()
  localStorage.removeItem('pin')
  router.push({name: 'StartingPage'})
}


const redirectGuard = () => {
  router.push({ name: 'PinInput' })
}

const redirectGuardCancel = () => {
  router.push({name: 'StartingPage'})
}

</script>

<template>
  <div class="command">
    <button class="navigation btnbening" @click="back">Kembali</button>
    <button class="navigation btnbening" @click="cancel">Batal</button>
  </div>
  <div class="new-pin-content">

    <div>
      <div class="new-pin-title">&nbsp;</div>
      <div class="new-pin-title">Ketik ulang pin 6 Digit anda</div>
      <div>
        <form>
          <input type="password" maxlength="1" />
          <input type="password" maxlength="1" />
          <input type="password" maxlength="1" />
          <input type="password" maxlength="1" />
          <input type="password" maxlength="1" />
          <input type="password" maxlength="1" />
        </form>
      </div>
      <div class="descriptionEnterPin">
        <div class="pinWrapimg">
          <img src="../../assets/keypads.jpg" alt="keypads" style="width:100%;border-radius: 10px;">
        </div>
        <h5 style="font-weight: 500;font-size: 20px;">Gunakan Keyboard ATM</h5>
        <div class="sentuh">
          <img src="../../assets/logo_sentuhNew.png" alt="sentuhLog" style="width:100%">
        </div>
      </div>
    </div>

    <!-- <div>
      <button @click="goToReEnterPIN()">Lanjut</button>
    </div> -->
  </div>
  <!-- Modal  -->
  <div id="open-modal" class="modal-window">
    <div class="modal-content">
      <div class="header-circle">
        <div class="dot">
          <img src="../../../src/assets/error-icon.png" />
        </div>
      </div>
      <div class="failed-scan-content">
        <div class="content-text">
          <div>PENCOCOKAN PIN GAGAL</div>
          <div class="content-text-margin">Pastikan menulis pin yang sama</div>
        </div>
        <div>
          <a href="#" title="Close">
            <button class="button-failed-scan" @click="redirectGuard">OK</button>
          </a>
        </div>
      </div>
    </div>
   </div>
  <!-- end modal -->
  <!-- Modal  -->
  <div id="open-cancelmodal" class="modal-window">
    <div class="modal-content">
      <div class="header-circle">
        <div class="dot">
          <img src="../../../src/assets/error-icon.png" />
        </div>
      </div>
      <div class="failed-scan-content">
        <div class="content-text">
          <div>INPUT PIN TELAH DIBATALKAN</div>
          <div class="content-text-margin">Batal Input Pin</div>
        </div>
        <div>
          <a href="#" title="Close">
            <button class="button-failed-scan" @click="redirectGuardCancel">OK</button>
          </a>
        </div>
      </div>
    </div>
   </div>
  <!-- end modal -->
</template>

<style scoped>
.new-pin-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 75px;
  flex-grow: 1;
  margin-bottom: 84px;
  justify-content: space-between;

  font-family: "Lato", sans-serif;
  font-weight: 400;
  font-size: 25px;
  line-height: 30px;
  text-align: center;
  color: var(--primary-blue);
}

.new-pin-title {
  margin-bottom: 32px;
  font-weight: 400;
  font-size: 25px;
  line-height: 30px;
  color: var(--primary-blue);
}

.new-pin-button {
  text-align: right;
  margin-top: 28px;
  font-weight: 700;
  font-size: 26px;
  line-height: 35px;
  color: #4a4545;
}

button {
  height: 83px;
  width: 269px;
  background: var(--primary-white);
  font-weight: 700;
  font-size: 28px;
  line-height: 34px;
  color: var(--primary-blue);
  filter: drop-shadow(0px 3px 16px rgba(124, 124, 124, 0.133));
  border: none;
  border-radius: 10px;
}

form {
  display: flex;
  justify-content: center;
  align-items: center;
}
input {
  margin: 0 0.5rem;
  padding: 0.5rem;
  border: none;
  border: 1px solid #707070;
  background: var(--primary-white);
  border-radius: 10px;
  width: 50px;
  height: 50px;
  text-align: center;
  font-size: 3rem;
}
.special {
  margin-top: 2rem;
  font-size: 2rem;
  opacity: 1;
  visibility: visible;
  transition: all 0.3s ease;
}

.btnbening {
  background-color: transparent !important;
  color: var(--primary-blue);
}

.descriptionEnterPin {
  display: flex;
  margin-top: 100px;
  padding: 6px 6px 0 6px;
  border-radius: 10px;
  box-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1);
  /* From https://css.glass */
background: rgba(255, 255, 255, 0.2);
border-radius: 16px;
box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
backdrop-filter: blur(1px);
-webkit-backdrop-filter: blur(1px);
/* border: 1px solid rgba(255, 255, 255, 0.3); */
position: relative;
overflow: hidden;
}

.descriptionEnterPin .sentuh {
  max-width: 350px;
  max-height: 550px;
  position: absolute;
  right: -270px;
  top: 80px;
  opacity: 0.3;
}
.pinWrapimg {
  max-width: 200px;
  overflow: hidden;
  margin-right: 20px;
  
}


</style>
