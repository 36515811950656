<script setup>
import { onMounted } from "vue";
import { useRouter } from "vue-router";
import PrintJSON from "../../assets/square.json";
import purpleJSON from "../../assets/arrowpurple.json";
import Grg from "../mixins/grg.js";
const router = useRouter();

//lifecycle hooks


onMounted(() => {
  Grg.methods.dispenserCard()
});

const redirectGuard = () => {
  router.push({ name: 'StartingPage' })
}


</script>

<template>
  <div class="darkfocus">
    <div class="waiting-create-content">
    <div class="waiting-create-title blink-hard">Mohon Tunggu Sedang Proses …</div>
    <!-- <div><img src="../../assets/printer-icon.png" /></div> -->
    <div style="position:absolute;left:130px;bottom:85px">
      <div style="display:flex">
        <LottieAnimation :animationData="purpleJSON" :height="80" :width="80" />
        <LottieAnimation :animationData="purpleJSON" :height="80" :width="80" />
        <LottieAnimation :animationData="purpleJSON" :height="80" :width="80" />
        <LottieAnimation :animationData="purpleJSON" :height="80" :width="80" />
        <LottieAnimation :animationData="purpleJSON" :height="80" :width="80" />
        <LottieAnimation :animationData="purpleJSON" :height="80" :width="80" />
      </div>
    </div>
    
    <div class="waiting-create-description">
      Proses Pencetakan Kartu Debit dimulai
    </div>
    <div style="display: flex;" class="square">
      <div class="rotate">
        <LottieAnimation :animationData="PrintJSON" :height="80" :width="80" />
      </div>
      <span>
        Tunggu Kartu anda diterbitkan dibagian ini
      </span>
    </div>
    <div class="printWrap">
      <img src="../../assets/grgprint.png" alt="i64" style="width:100%;">
    </div>
  </div>
  </div>
  <!-- Modal  -->
  <div id="open-modal" class="modal-window">
    <div class="modal-content">
      <div class="header-circle">
        <div class="dot">
          <img src="../../../src/assets/error-icon.png" />
        </div>
      </div>
      <div class="failed-scan-content">
        <div class="content-text">
          <div>GAGAL CETAK KARTU</div>
          <div class="content-text-margin">Maaf, stok kartu habis. Harap hubungi petugas</div>
        </div>
        <div>
          <a href="#" title="Close">
            <button class="button-failed-scan" @click="redirectGuard">OK</button>
          </a>
        </div>
      </div>
    </div>
   </div>
  <!-- end modal -->
</template>

<style scoped>
.waiting-create-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 75px;
  flex-grow: 1;
  margin-bottom: 84px;
  justify-content: space-around;

  font-family: "Lato", sans-serif;
  font-weight: 400;
  font-size: 25px;
  line-height: 30px;
  text-align: center;
  color: var(--primary-blue);
}

.waiting-create-title {
  font-weight: 700;
  font-size: 25px;
  line-height: 30px;
  text-align: center;
  color: var(--primary-white)!important;
}

.waiting-create-description {
  font-weight: 500;
  font-size: 23px!important;
  line-height: 30px;
  text-align: center;
  color: #fff!important;
  margin-top: 70px;
} 

.printWrap {
  max-width: 1200px;
  max-height: 1200px;
}

.darkfocus {
  position: absolute;
  width: 100%;
  top: 144.9px;
  height: 84.9vh;
  /* background: rgba(0, 0, 0, 0.4); */
  background: linear-gradient(gray, whitesmoke);
  z-index: 10;
  overflow: hidden;
}

.square {
  position: absolute;
  bottom: 72px; 
  right: 170px;

}

.square .rotate {
  
  /* -moz-transform: rotate(-73deg);
  -webkit-transform: rotate(-73deg);
  -ms-transform: rotate(-73deg);
  -o-transform: rotate(-73deg); */
  /* -moz-transform: rotate(-73deg); */
  /* -webkit-transform: rotate(-73deg); */
  -ms-transform: skewY(16.5deg);
  transform: skewY(16.5deg);

}

.square span {
  margin-top: 19px;
  color: #eaeaea;
  padding: 10px;
  max-height: 30px;
  background-color: var(--primary-blue);
  border-radius: 5px;
  font-size: 22px;
  box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
}
.blink-hard {
  animation: blinker 1s step-end infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}
</style>
